import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../utils/userContext";


const accountMange = ["/profile", "/security", "/privacy-data"];
const Header = () => {
  const { userImage, userName, userContact, userWalletBalance } = useContext(UserContext);
  const [requestId, setRequestId] = useState(() => {
    var id = localStorage.getItem("request_id");
    return id ? id : null;
  });
  const [language, setLanguage] = useState(() => {
    var lan = localStorage.getItem("i18nextLng");
    return lan ? lan : null;
  });
  const location = useLocation();
  var location_path = location.pathname;
  if (location_path.includes("ride-detail")) {
    location_path = location_path.split("/")[1];
  }
  if (location_path.includes("ride-reciept")) {
    location_path = location_path.split("/")[1];
  }
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };
  const handleClose = () => {
    setShow(false);

  };
  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <header
        className={
          location.pathname !== "/dashboard" &&
            location.pathname !== "/my-trips" &&
            location.pathname !== "/tax-page" &&
            location.pathname !== "/coupons" &&
            location.pathname !== "/settings"
            ? "header header-7"
            : "header header-7 haeder-shadow"
        }
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link
                  className="d-flex"
                  to={
                    !accountMange.includes(location.pathname)
                      ? "/"
                      : "/dashboard"
                  }
                  style={{ textDecoration: "none" }}
                >
                  {/*<img alt="Ecom" src="assets/imgs/template/logo-rove-dark.png" />*/}

                  <img
                    alt="Ecom"
                    src="/assets/imgs/template/logo-rove-light.png"
                  />
                </Link>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu d-none d-xl-block">
                  {location.pathname !== "/" &&
                    location.pathname !== "/terms-condition" &&
                    location.pathname !== "/contact-number" &&
                    location.pathname !== "/login" &&
                    location.pathname !== "/otp" && (
                      <>
                        {/* {requestId &&location_path !== "ride-detail" && (
                          <div className="fixed-bottom">
                            <div className="float-end mr-30 pb-30">
                              <Link
                                to={"/ride-detail/" + requestId}
                                className="btn btn-primary"
                              >
                                Go to ride
                              </Link>
                            </div>
                          </div>
                        )} */}
                        <ul className="main-menu">
                          <li
                            className={
                              location.pathname === "/dashboard" ||
                                location_path === "ride-detail"
                                ? "li-border"
                                : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/dashboard"
                            >
                              <i
                                className="fa fa-car"
                                style={{
                                  fontSize: "15px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Ride
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/wallet" ? "li-border" : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/wallet"
                            >
                              <i
                                className="bi bi-wallet-fill"
                                style={{
                                  fontSize: "15px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Wallet
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/my-trips"
                                ? "li-border"
                                : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/my-trips"
                            >
                              <i
                                className="fa fa-location-arrow"
                                style={{
                                  fontSize: "15px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              My Rides
                            </Link>
                          </li>

                          <li
                            className={
                              location.pathname === "/payments" ? "li-border" : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/payments"
                            >
                              <img
                                src="/assets/imgs/template/icons/reciept-icon.svg"
                                alt="reciept-icon"
                                className="me-2 pb-1"
                              />
                              Payments
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/saved-places"
                                ? "li-border"
                                : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/saved-places"
                            >
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                                style={{
                                  fontSize: "15px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Saved Places
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/coupons"
                                ? "li-border"
                                : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/coupons"
                            >
                              <img
                                aria-hidden="true"
                                style={{
                                  height: "19px",
                                  fontSize: "15px",
                                  marginRight: "10px",
                                }}
                                src="/assets/imgs/icons/discount.png"
                              />
                              Coupons
                            </Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/settings"
                                ? "li-border"
                                : ""
                            }
                          >
                            <Link
                              className="active text-white"
                              style={{ paddingRight: "15px" }}
                              to="/settings"
                            >
                              {/* <Link to="/settings"> */}
                              <i className="fa fa-cog" aria-hidden="true"></i>
                              Settings
                              {/* </Link> */}
                            </Link>
                          </li>

                          {/* <li
                        className={
                          location.pathname === "/wallet" && "li-border"
                        }
                      >
                        <Link
                          className="active text-white"
                          style={{ paddingRight: "15px" }}
                          to="/wallet"
                        >
                          <i className="fa fa-bell-o" aria-hidden="true" style={{ fontSize: "15px", marginRight: "10px" }}></i>
                          Notifications
                        </Link>
                      </li> */}
                        </ul>
                      </>
                    )}
                </nav>
                <div
                  className="burger-icon burger-icon-white"
                  onClick={() => setShow(true)}
                >
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>
              {/* <div className="header-right d-none d-xl-block"> */}
              {location.pathname !== "/login" &&
                location.pathname !== "/register" &&
                location.pathname !== "/dashboard" &&
                location.pathname !== "/my-trips" &&
                location.pathname !== "/wallet" &&
                location.pathname !== "/about" &&
                location_path !== "ride-reciept" &&
                location.pathname !== "/saved-places" &&
                location.pathname !== "/otp" &&
                location.pathname !== "/contact-number" &&
                location.pathname !== "/payments" &&
                location.pathname !== "/privacy-data" &&
                location.pathname !== "/faqs" &&
                location.pathname !== "/feedback" &&
                location.pathname !== "/privacy-policy" &&
                location.pathname !== "/security" &&
                location.pathname !== "/profile" &&
                location.pathname !== "/coupons" &&
                location.pathname !== "/contact-us" &&
                location_path !== "ride-detail" &&
                location.pathname !== "/settings" && (
                  <div className="header-right d-none d-xl-block">
                    {" "}
                    <div className="box-button-login d-inline-block mr-20 align-middle">
                      <div className="dropdown-afterlogin pt-0">
                        <Link
                          className="btn text-14-medium hover-up d-flex align-items-center"
                          to="/login"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            className=" me-1"
                          >
                            <path
                              d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1Zm8 11c0 .7-.1 1.4-.3 2-.6-1.5-1.6-3.1-3-4.7l1.8-1.8c1 1.3 1.5 2.8 1.5 4.5ZM6.5 6.5c1.3 0 3.6.8 6 2.9l-3.2 3.2C7.1 9.8 6.5 7.5 6.5 6.5Zm8.1 5c2.3 2.7 2.9 5 2.9 6-1.3 0-3.6-.8-6-2.9l3.1-3.1Zm1.9-6.1-1.9 1.9c-1.6-1.4-3.2-2.4-4.7-3 .7-.2 1.3-.3 2-.3 1.8 0 3.3.5 4.6 1.4ZM4 12c0-.7.1-1.4.3-2 .6 1.5 1.6 3.1 3 4.7l-1.8 1.8C4.5 15.2 4 13.7 4 12Zm3.5 6.6 1.9-1.9c1.6 1.4 3.2 2.4 4.7 3-.7.2-1.3.3-2 .3-1.8 0-3.3-.5-4.6-1.4Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          {language == "ar" ? "AR" : "EN"}
                        </Link>
                        <div
                          className="dropdown-content"
                          style={{ minWidth: "100px", left: "-14px" }}
                        >
                          <a className={language != "ar" ? "active" : ""} onClick={() => changeLanguage('en')}>English</a>
                          <a className={language == "ar" ? "active" : ""} onClick={() => changeLanguage('ar')}>Arabic</a>
                        </div>
                      </div>
                    </div>
                    <div className="box-button-login d-inline-block mr-20 align-middle">
                      <Link
                        className="btn text-14-medium hover-up"
                        to="/faqs"
                      >
                        Help
                      </Link>
                    </div>
                    <div className="box-button-login register-button d-none2 d-inline-block align-middle ">
                      <Link className="btn hover-up px-2" to="/login">
                        {t("requestARide")}
                      </Link>
                    </div>
                  </div>
                )}
              {(location.pathname === "/dashboard" ||
                location.pathname === "/my-trips" ||
                location.pathname === "/wallet" ||
                location.pathname === "/profile" ||
                location.pathname === "/contact-us" ||
                location_path === "ride-reciept" ||
                location.pathname === "/payments" ||
                location.pathname === "/about" ||
                location.pathname === "/faqs" ||
                location.pathname === "/feedback" ||
                location.pathname === "/privacy-policy" ||
                location_path === "ride-detail" ||
                location.pathname === "/coupons" ||
                location.pathname === "/contact-us" ||
                location.pathname === "/saved-places" ||
                location.pathname === "/tax-page" ||
                location.pathname === "/settings") && (
                  <div className="header-right d-none d-xl-block" style={{ minWidth: "100px" }}>
                    <div className="box-button-login d-inline-block mr-20 align-middle">
                      <div className="dropdown-afterlogin">
                        <div className="d-flex align-items-center gap-1">
                          <Link to="/profile">
                            <img
                              // src={ userImage ? "https://www.riderove.com/dev_rove/uploads/customer/"+userImage : "/assets/imgs/page/homepage1/person.jpeg"}
                              src={userImage && "https://www.riderove.com/dev_rove/uploads/customer/" + userImage}
                              // src={ loginUser && loginUser.user_profile ? "https://www.riderove.com/dev_rove/uploads/customer/"+loginUser.user_profile.user_image : "/assets/imgs/page/homepage1/person.jpeg"}
                              onError={(e) => (e.target.src = userImage != "" ? "https://rovemain.rovesolution.com/uploads/customer/" + userImage : "/assets/imgs/page/homepage1/person.jpeg")}
                              className="dropbtn"
                              alt="person"
                            />
                          </Link>
                          {/* <i
                            className="fa fa-chevron-down"
                            style={{
                              fontSize: "15px",
                              marginLeft: "2px",
                              color: "#fff",
                            }}
                          ></i> */}
                        </div>
                        {/* <div className="dropdown-content">
                          <Link to="/profile">
                            <i className="fa fa-user" aria-hidden="true"></i>
                            Manage account
                          </Link>
                           <Link to="/settings">
                            <i className="fa fa-cog" aria-hidden="true"></i>
                            Setting
                          </Link> 
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </header>
      {show && (

        <Offcanvas id="headercanvas" show={show} onHide={handleClose} placement="left">
          <Offcanvas.Header closeButton >
          <img alt="Ecom" src="/assets/imgs/template/logo-rove-dark.png" className="w-25"/>
          </Offcanvas.Header>
          <Offcanvas.Body className="mobile-header-body ">
            <div className="d-flex flex-column mobile-nav mt-3 ">
              {location.pathname !== "/" &&
                location.pathname !== "/contact-number" &&
                location.pathname !== "/terms-condition" &&
                location.pathname !== "/login" &&
                location.pathname !== "/otp" && (
                  <div>

                    <Link
                      className="active text-black "
                      style={{ textDecoration: "none" }}
                      to="/profile"
                      onClick={handleClose}
                    >
                      <div className="user-info">
                        <div className="d-flex mb-0">
                          <h4>{userName}</h4>
                          <img src="/assets/imgs/icons/arrow_right/direction-right 2.png" alt="arrow" />
                        </div>
                        <p>{userContact}</p>
                      </div>
                    </Link>

                    <ul className="main-menu d-flex flex-column gap-3 mt-4 ps-3">
                      <li className="mb-1">
                        <Link
                          className="active "
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/dashboard"
                          onClick={handleClose}
                        >
                          {/* <i
                        className="fa fa-car"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      ></i> */}
                          <img
                            src="/assets/imgs/icons/car/menu_car@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                          />
                          Request a Ride
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link
                          className="d-flex align-items-center justify-content-between text-decoration-none"
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/wallet"
                          onClick={handleClose}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src="/assets/imgs/icons/wallet/wallet.png"
                              alt="wallet-icon"
                              style={{ height: "20px", width: "20px" }}
                            />
                            <span>Wallet</span>
                          </div>
                          <div className="d-flex align-items-center  gap-1 p-2" style={{ background:'#cfd6d9' , borderRadius:'2px' }}>
                            <sub>KD</sub>
                            <h6 className="mb-0 fw-bold" >{userWalletBalance}</h6>
                          </div>
                        </Link>
                      </li>

                      <li className="mb-1">
                        <Link
                          className="active "
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/my-trips"
                          onClick={handleClose}
                        >
                          {/* <i
                        className="fa fa-location-arrow"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      ></i> */}
                          <img
                            src="/assets/imgs/icons/route/destination 02@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                          />
                          My Rides
                        </Link>
                      </li>
                      <li className="mb-1" >
                        <Link
                          className="active  "
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/payments"
                        >
                          <img
                            src="/assets/imgs/icons/bill/bill.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                          />
                          Payments
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link
                          className="active  "
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/saved-places"
                          onClick={handleClose}
                        >
                          {/* <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      ></i> */}
                          <img
                            src="/assets/imgs/icons/ic_map/map-03@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                          />
                          Saved Places
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link
                          className="active  "
                          style={{ color: '#1f3c4b' }}
                          to="/coupons"
                        >
                          <img
                            aria-hidden="true"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                            src="/assets/imgs/icons/coupon/badge-percent.png"
                          />
                          Coupons
                        </Link>

                      </li>
                      <li className="mb-1">
                        <Link
                          className="active  "
                          style={{ color: '#1f3c4b' }}
                          to="/contact-us"
                        >
                          <img
                            aria-hidden="true"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                            src="/assets/imgs/icons/mail/mail@2x.png"
                          />
                          Contact Us
                        </Link>

                      </li>
                      {/* <li className="mb-1">
                    <Link
                      className="active text-black"
                      style={{ paddingRight: "15px" }}
                      to="/profile"
                       onClick={handleClose}
                    >
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      ></i>
                      Manage account
                    </Link>
                  </li> */}

                      <li className="mb-1">
                        <Link
                          className="active"
                          style={{ paddingRight: "15px", color: '#1f3c4b' }}
                          to="/settings"
                          onClick={handleClose}
                        >
                          {/* <i
                        className="fa fa-cog"
                        aria-hidden="true"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      ></i> */}
                          <img
                            src="/assets/imgs/icons/setting/setting@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}

                          />
                          Settings
                        </Link>
                      </li>
                    </ul>
                    <div className="mobile-header-social">
                      <div className="d-flex justify-content-between">
                        <div>
                          <a href="https://www.instagram.com/rideroveapp?igsh=MW9hdG9vemhjZDN5cw==">
                            <img
                              src="/assets/imgs/icons/instagram/instagram@2x.png"
                              alt="reciept-icon"
                              className="me-2 pb-1"
                              style={{ height: "20px", width: "20px" }}
                            />
                          </a>
                          <img
                            src="/assets/imgs/icons/ic_callimageset/call-user@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}

                          />
                        </div>
                        <p className="me-3">Ver 1.0</p>
                      </div>
                    </div>
                  </div>
                )}

              {
                location.pathname !== "/register" &&
                location.pathname !== "/dashboard" &&
                location.pathname !== "/my-trips" &&
                location.pathname !== "/wallet" &&
                location.pathname !== "/about" &&
                location_path !== "ride-reciept" &&
                location.pathname !== "/saved-places" &&
                location.pathname !== "/otp" &&
                location.pathname !== "/payments" &&
                location.pathname !== "/privacy-data" &&
                location.pathname !== "/faqs" &&
                location.pathname !== "/feedback" &&
                location.pathname !== "/privacy-policy" &&
                location.pathname !== "/security" &&
                location.pathname !== "/profile" &&
                location.pathname !== "/contact-us" &&
                location.pathname !== "/coupons" &&
                location_path !== "ride-detail" &&
                location.pathname !== "/settings" && (
                  <>
                    {" "}
                    <div className="box-button-login d-inline-block mr-20 align-middle">
                      <div className="dropdown-afterlogin pt-0">
                        <Link
                          className="btn text-14-medium text-rove hover-up d-flex align-items-center"
                          // to="/login"
                          onClick={handleClose}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            className=" me-1"
                          >
                            <path
                              d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1Zm8 11c0 .7-.1 1.4-.3 2-.6-1.5-1.6-3.1-3-4.7l1.8-1.8c1 1.3 1.5 2.8 1.5 4.5ZM6.5 6.5c1.3 0 3.6.8 6 2.9l-3.2 3.2C7.1 9.8 6.5 7.5 6.5 6.5Zm8.1 5c2.3 2.7 2.9 5 2.9 6-1.3 0-3.6-.8-6-2.9l3.1-3.1Zm1.9-6.1-1.9 1.9c-1.6-1.4-3.2-2.4-4.7-3 .7-.2 1.3-.3 2-.3 1.8 0 3.3.5 4.6 1.4ZM4 12c0-.7.1-1.4.3-2 .6 1.5 1.6 3.1 3 4.7l-1.8 1.8C4.5 15.2 4 13.7 4 12Zm3.5 6.6 1.9-1.9c1.6 1.4 3.2 2.4 4.7 3-.7.2-1.3.3-2 .3-1.8 0-3.3-.5-4.6-1.4Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          {language == "ar" ? "ARABIC" : "ENGLISH"}
                        </Link>
                        <div
                          className="dropdown-content"
                          style={{ minWidth: "100px", left: "-14px" }}
                        >
                          <a className={language != "ar" ? "active" : ""} onClick={() => { changeLanguage('en'); setShow(false); }}>English</a>
                          <a className={language == "ar" ? "active" : ""} onClick={() => { changeLanguage('ar'); setShow(false); }}>Arabic</a>
                          {/* <Link to="/"  onClick={handleClose}>
                          English
                        </Link>
                        <Link to="/" onClick={() => setShow(false)}>
                          Arabic
                        </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="box-button-login d-inline-block mr-20 align-middle">
                      <Link
                        className="btn text-14-medium text-rove hover-up"
                        to="/faqs"
                        onClick={handleClose}
                      >
                         <img
                            aria-hidden="true"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                            src="/assets/imgs/icons/mail/mail@2x.png"
                          />
                        Help
                      </Link>
                    </div>
                    <div className="box-button-login register-button d-none2 d-inline-block align-middle ">
                      <Link
                        className="btn hover-up text-rove px-2"
                        to="/login"
                        onClick={handleClose}
                      >
                           <img
                            src="/assets/imgs/icons/car/menu_car@2x.png"
                            alt="reciept-icon"
                            className="me-2 pb-1"
                            style={{ height: "20px", width: "20px" }}
                          />
                        {t("requestARide")}
                      </Link>
                    </div>
                  </>
                )}
            </div>
          </Offcanvas.Body >
        </Offcanvas >
      )}
    </>
  );
};

export default Header;
