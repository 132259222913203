function isSafari() {
  const ua = navigator.userAgent;
  const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua) && !/Edg/.test(ua);
  localStorage.setItem("isSafari", isSafari);
  return isSafari;
}

export async function requestNotificationPermission(retryCount = 0) {
  // const staticToken = "fvUHPYgbEPRQNce-T6wLwO:APA91bGZq3JrXu5_-SPGGBwj55j3i6JL5Wh3DzzYWKm6zFuc7sBm1nMUXSr605zlfMwKBmmrvGBaYJD9v7NVrFq88gniQwiXiXZRppCZgCJBmUD8p5wAWwY";
  const staticToken = "cQoXQdqjF0ad_20Q9LzF2Y:APA91bH6wmGSS_O1wsIdOOTGzLK7ZduA3n-5Iy6oIqY3y1RP30QpvR0TX_-GM2uhnpoBavCdS_pv0SHR8vjDe9wjo-N_ijFKm2AacuQeZkZINRIah2T6uXk";
  
  if (!("Notification" in window)) {
    alert("Your browser does not support notifications. Please use a supported browser.");
    return Promise.resolve(staticToken);
  }

  const permission = await Notification.requestPermission();
  
  if (permission === "granted") {
    console.log("Notification permission granted.");
    
    if (isSafari()) {
      // Return the static token for Safari
      console.log("Safari detected. Returning static token.");
      return Promise.resolve(staticToken);
    } else {
      // Dynamically import Firebase modules and get the token
      const { getToken } = await import("firebase/messaging");
      const { messaging } = await import("../firebase/firebase");
      
      try {
        const firebaseToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
        });
        console.log("Firebase token retrieved:", firebaseToken);
        return firebaseToken;
      } catch (error) {
        console.error("Error getting Firebase token:", error);
        throw error;
      }
    }
  } else if (permission === "denied") {
    console.log("Notification permission denied.");
    return Promise.resolve(staticToken);
    // if (retryCount < 3) {  // Limit retries to avoid annoying the user
    //   console.log(`Retrying permission request in 5 seconds... Attempt ${retryCount + 1}`);
    //   setTimeout(() => requestNotificationPermission(retryCount + 1), 5000);
    // } else {
    //   alert("Please enable notifications manually in your browser settings.");
    // }
    throw new Error("Notification permission denied.");
  } else {
    console.log("Notification permission not granted.");
    throw new Error("Notification permission not granted.");
  }
}



/* // import { getToken } from "firebase/messaging";
// import { messaging } from "../firebase/firebase";

// function isSafari() {
//   const ua = navigator.userAgent;
//   const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua) && !/Edg/.test(ua);
//   return isSafari;
//   localStorage.setItem("isSafari",isSafari);
// }

export function requestNotificationPermission() {
  const staticToken = "coTO-d89DgO_PezC15GuSf:APA91bHAoXyGeeJZijuAFs18hSUpKhu4HDGpZv0Y4dA1ilBHc1n_nse79jlTItexhb2EulQOQ0lFgdzOUgPfJbKdhIYD2BroaYnJKGG1QX7RA5t5WYhSuqE"
  if (!("Notification" in window)) {
    alert("Your browser does not support notifications. Please use a supported browser.");
    return Promise.resolve(staticToken);
  }

  return Notification.requestPermission().then((permission) => {
    return Promise.resolve(staticToken); // Return it as a resolved promise
    // if(isSafari()){
    // }
    console.log("DDDDDD")
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // return getToken(messaging, {
      //   vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
      // });
    } else if (permission === "denied") {
      console.log("Notification permission denied.");
      throw new Error("Notification permission denied.");
    } else {
      console.log("Notification permission not granted.");
      throw new Error("Notification permission not granted.");
    }
  });
}
 */