import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { feedbackApi } from '../../api/api'
import { toast } from "react-toastify";

const FeedbackPage = () => {
  const [userDetail, setUserDetail] = useState({});
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    var user = localStorage.getItem("user_data");
    var user_number = localStorage.getItem("user_number");

    if (user) {
      setUserDetail(JSON.parse(user));
    }

    if (user_number) {
      setNumber(user_number.replace(/\s/g, ''));
    }
  }, []);

  useEffect(() => {
  }, [userDetail, number, message]);

  const post_feedbackApi = async (e) => {
    e.preventDefault();
    try {
      var data1 = {
        user_type: userDetail?.user_type || "0",
        user_id: userDetail?.user_profile_id || "",
        name: userDetail?.name_eng || "",
        email: userDetail?.user_email || "",
        message: message,
        number: number || ""
      };

      var form = new FormData();
      Object.keys(data1).forEach(key => form.append(key, data1[key]));

      var res = await feedbackApi(form);
      var data = res.data;
      if (data.status == "1") {
        setUserDetail(data);
        setMessage('');
        toast("Feedback Sent Successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        console.log('Internal Server Error');
        setSuccessMessage('Error sending feedback. Please try again.');
      }
    } catch (error) {
      console.log(error);
      setSuccessMessage('An error occurred. Please try again.');
    }
  };

  return (
    <main className="main">
      <section className="section mt-50 mb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 mb-50">
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 px-5 mb-50 ">
              <div className="text-center">
                <h2 className="mb-5 wow fw-bold fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                  Feedback
                </h2>
                <p className="fw-bold fs-5 mb-0">Your feedback is important and valuable to us.</p>
                <p className="fw-bold fs-5 mb-0">Send your message through the form below.</p>
                <p className="fw-bold fs-5 mb-0">We will be in touch as soon as possible.</p>
              </div>
              <div className="box-login mt-40">
                <div className="form-contact form-comment wow fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                  <form onSubmit={post_feedbackApi}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group focused">
                          <input
                            type="text"
                            id="name"
                            className={`form-control`}
                            placeholder="Name"
                            name="name"
                            value={userDetail.name_eng}
                            onChange={(e) => setUserDetail({...userDetail, name_eng: e.target.value})}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group focused">
                          <input
                            type="text"
                            id="email"
                            className={`form-control`}
                            placeholder="Email"
                            name="email"
                            value={userDetail.user_email}
                            onChange={(e) => setUserDetail({...userDetail, user_email: e.target.value})}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          cols="3"
                          rows="3"
                          className="form-control"
                          placeholder="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-lg-12 mt-3">
                        <button className="btn btn-primary fw-bold w-100" type="submit">
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {successMessage && (
                <div className="mt-3">
                  <div className={`alert ${successMessage.includes('Error') ? 'alert-danger' : 'alert-success'}`} role="alert">
                    {successMessage}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img src="/assets/imgs/icons/Vector@3x.png" alt="my-trip-2" className="mb-3" />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link className="btn btn-primary d-flex justify-content-center align-items-center gap-3" to="/dashboard">
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FeedbackPage;
