import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Button } from "react-bootstrap";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import Spinner from "react-bootstrap/Spinner";

import {
  getProfileDataApi,
  addWalletTapWebAPI,
  retrieveTapCustomerApi,
  createTapChargeApi,
  addCardWalletAPI,
  walletHistoryV2,
  checkUserNumberExistAppApi,
  transferWalletFreindKnetApi,
  redeemVoucher,
  saveTapCardApi,
  addCardApi,
} from "../../api/api";
import {
  TapCard,
  Currencies,
  Direction,
  Edges,
  Locale,
  Theme,
  Scope,
  ColorStyle,
  tokenize,
  saveCard,
} from "@tap-payments/card-web";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../utils/userContext";


const tap_secret =
  process.env.REACT_APP_TAP_PAYMENT_LIVE == "0"
    ? process.env.REACT_APP_TAP_PAYMENT_SECRET_TEST
    : process.env.REACT_APP_TAP_PAYMENT_SECRET;
const tap_public =
  process.env.REACT_APP_TAP_PAYMENT_LIVE == "0"
    ? process.env.REACT_APP_TAP_PAYMENT_PUBLIC_TEST
    : process.env.REACT_APP_TAP_PAYMENT_PUBLIC;

const historyStrings = {
  wallet_history: "Wallet History",
  no_wallet_history: "No wallet history",
  paid_fro_crn_no: "Paid for ride:",
  refund_for_crn_no: "Refund for ride:",
  credited_by_ride_rove_admin: "Credited by admin",
  credited_from_voucher: "Credited from voucher",
  credited_from_referral_code: "Credited from referral code",
  credited_by_you_with: "Credited by you with KNET/CARD",
  long_ride_duration: "Long Ride Duration",
  credite_by_card: "Credited by you with CARD",
  credite_by_knet: "Credited by you with KNET",
  cash_refund_for_crn_no: "CASH refund for ride:",
  knet_refund_for_crn_no: "KNET refund for ride:",
  wallet_transfer: "Wallet Transfer",
  credited_from: "Credited from",
  debited_to: "Debited to",
};

const Wallet = () => {
  const location = useLocation();
  const {enableFeaturesList,commonPending} = useContext(UserContext);
  const controlref = useRef();
  const walletTransferAmountRef = useRef();
  const transferMessageRef = useRef();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });

  const [forMeShow, setForMeShow] = useState(false);
  const [formDataWallet, setFormDataWallet] = useState({ message: "", amount: "",number:"" });
  const [transferButton, setTransferButton] = useState(true);

  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const [forSavedCreditCardShow, setForSavedCreditCardShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
 
  const [voucher, setVoucher] = useState("");

  const [historyModal, setHistoryModal] = useState(false);
  const [walletHistories, setWalletHistories] = useState();
  const [detailHistoryModal, setDetailHistoryModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState();

  const [userProfileData, setUserProfileData] = useState();
  const handleForMeClose = () => setForMeShow(false);
  const handleForCreditCardClose = () => setForCreditCardShow(false);
  const handleForSavedCreditCardClose = () => setForSavedCreditCardShow(false);
  const detailHistoryModalClose = () => setDetailHistoryModal(false);

  const [createEnabled, setCreateEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [customerObj, setCustomerObj] = useState({});
  const [savedCards, setSavedCards] = useState([]);
  const [selectCard, setSelectCard] = useState({});
  const [dialcode, setDialcode] = useState();
  let iti = null;
  const [isModalOpen, setModalOpen] = useState(false);
const [iframeSrc, setIframeSrc] = useState("");
const [isSafari, setIsSafari] = useState(()=>{
  var sf = localStorage.getItem("isSafari");
  return sf;
})
  const [submitDisable, setSubmitDisable] = useState(false);


  const historyModalClose = () => setHistoryModal(false);
  useEffect(() => {
    if (userDetail) {
      if (!userProfileData) {
        
        get_profile_data();
      }
      if (!walletHistories) {
        wallet_history_v2_api();
      }
      // get_enable_features_list()
    }
    var text_input = document.querySelector("#other_amount");
    if (location.state) {
      const { topUp } = location.state;

      if (topUp && !text_input.value) {
        text_input.value = topUp;
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (userProfileData) {
      fetchCustomer();
    }
  }, [userProfileData]);

  const cardSelectHandler = (e, card) => {
    setSelectCard(card);
    var list = document.getElementsByClassName("card_select");
    var cards = [].slice.call(list);
    cards.map((card) => {
      card.classList.remove("active");
    });
    var element = e.currentTarget;
    element.classList.add("active");
  };

  const TimeZoneToday = (time, format = "Do MMM YYYY, h:mm a") => {
    const curruntDate = new Date();
    const inputDate = new Date(time);
  
    // Calculate the difference in days between the input date and the current date
    const diffInDays = Math.floor((curruntDate - inputDate) / (1000 * 60 * 60 * 24));
  
    if (diffInDays === 0) {
      return `Today ${moment.utc(time).tz("Asia/Kuwait").format("h:mm a")}`;
    } else if (diffInDays === 1) {
      return `Yesterday ${moment.utc(time).tz("Asia/Kuwait").format("h:mm a")}`;
    } else {
      return moment.utc(time).tz("Asia/Kuwait").format(format);
    }
  };
  

  const TimeZone = (time, format = "Do MMM YYYY, h:mm a") => {
    

    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };

  const wallet_history_v2_api = async () => {
    try {
      var form = new FormData();
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);

      var res = await walletHistoryV2(form);
      var data = res.data;

      if (data.wallet_history) {
        var updated_history = data.wallet_history.map((h) => {
          var payment_type = h.payment_type;
          var debit = parseFloat(h.debit);
          switch (payment_type) {
            case "10":
              if (debit > 0) {
              } else {
                if (h.rand_ride_id != null && h.rand_ride_id != "") {
                  h.text =
                    historyStrings.refund_for_crn_no + " " + h.rand_ride_id;
                } else {
                  h.text =
                    historyStrings.refund_for_crn_no + " " + h.request_id;
                }
              }
              break;

            case "0":
              if (debit > 0) {
              } else {
                h.text = historyStrings.credite_by_card;
              }
              break;

            case "8":
              if (debit > 0) {
              } else {
                h.text = historyStrings.credite_by_knet;
              }
              break;

            case "1":
              if (debit > 0) {
                if (h.rand_ride_id != null && h.rand_ride_id != "") {
                  h.text =
                    historyStrings.paid_fro_crn_no + " " + h.rand_ride_id;
                } else {
                  h.text = historyStrings.paid_fro_crn_no + " " + h.request_id;
                }
              } else {
                if (h.rand_ride_id != null && h.rand_ride_id != "") {
                  h.text =
                    historyStrings.cash_refund_for_crn_no +
                    " " +
                    h.rand_ride_id;
                } else {
                  h.text =
                    historyStrings.cash_refund_for_crn_no + " " + h.request_id;
                }
              }
              break;

            case "9":
              if (debit > 0) {
                h.text = "NA";
              } else {
                if (h.rand_ride_id != null && h.rand_ride_id != "") {
                  h.text =
                    historyStrings.knet_refund_for_crn_no +
                    " " +
                    h.rand_ride_id;
                } else {
                  h.text =
                    historyStrings.knet_refund_for_crn_no + " " + h.request_id;
                }
              }
              break;

            case "2":
              if (debit > 0) {
                h.text = "";
              } else {
                h.text =
                  historyStrings.credited_by_ride_rove_admin +
                  " - " +
                  h.admin_name;
              }
              break;

            case "5":
              if (debit > 0) {
                h.text = "";
              } else {
                h.text =
                  historyStrings.credited_by_ride_rove_admin +
                  " " +
                  h.admin_name;
              }
              break;

            case "3":
              if (debit > 0) {
                h.text = "";
              } else {
                h.text = historyStrings.credited_from_voucher;
              }
              break;

            case "4":
              if (debit > 0) {
                h.text = "";
              } else {
                h.text = historyStrings.credited_from_referral_code;
              }
              break;

            case "6":
              if (debit > 0) {
                h.text =
                  historyStrings.wallet_transfer +
                  " - " +
                  historyStrings.debited_to +
                  " " +
                  h.transfer_user_name;
              } else {
                h.text =
                  historyStrings.wallet_transfer +
                  " - " +
                  historyStrings.credited_from +
                  " " +
                  h.transfer_user_name;
              }
              break;
          }

          if (debit > 0) {
            h.src = "/assets/imgs/icons/direction-up.png";
          } else {
            h.src = "/assets/imgs/icons/direction-down.png";
          }
          h.date = TimeZoneToday(h.created_date);
          return h;
        });

        setWalletHistories(updated_history);
      }
    } catch (error) {
      console.error("wallet_history_v2_api", error);
    }
  };

  const cardConfirmSelectHandler = async () => {
    setForSavedCreditCardShow(false);
    if (selectCard == "") {
      walletAddCreditCardHandler();
    } else {
      let amount = document.getElementById("other_credit_amount").value;

      document.getElementById("other_credit_amount").value = "";
      if (document.getElementById("credit_amount_" + amount)) {
        document.getElementById("credit_amount_" + amount).checked = false;
      }
      
      if (enableFeaturesList && enableFeaturesList.max_wallet_credit) {
        if ((parseFloat(amount) + parseFloat(userProfileData.user_profile.wallet)) > parseFloat(enableFeaturesList.max_wallet_credit)) {
          toast.error(`You can't add more than KD ${enableFeaturesList.max_wallet_credit} in your wallet.`, {
            position: "top-center",
            autoClose: 3000,
          });
          return false;
        }
      }

      var form = new FormData();
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append(
        "tap_customer_id",
        userProfileData.user_profile.tap_customer_id
      );
      form.append("tap_card_id", selectCard.id);
      form.append("amount", amount);
    
      var res = await addCardWalletAPI(form);
      var data = res.data;
      console.log(data);
      toast(data.message, {
        position: "top-center",
        autoClose: 3000,
      }); 

      get_profile_data();
    }
  };

  const selectCreditAmountHandler = async (e) => {
    let amount = e.target.value;
    document.getElementById("other_credit_amount").value = amount;
  };

  const amountCreditCardHandler = async (e) => {
    let amount = e.target.value;
    if (amount) {
      document.getElementById("credit_amount_" + amount).checked = false;
    }
  };

  const walletAddTapHandler = async (e) => {
    let amount = document.getElementById("other_amount").value;
    if (!amount || parseFloat(amount) == 0) {
      toast.error("Please, enter valid amount.", {
        position: "top-center",
        autoClose: 3000,
      }); 
      return false;
    }
    if (enableFeaturesList && enableFeaturesList.max_wallet_credit) {
      if ((parseFloat(amount) + parseFloat(userProfileData.user_profile.wallet)) > parseFloat(enableFeaturesList.max_wallet_credit)) {
        toast.error(`You can't add more than KD ${enableFeaturesList.max_wallet_credit} in your wallet.`, {
          position: "top-center",
          autoClose: 3000,
        });
        return false;
      }
    }
    setSubmitDisable(true);
    var data1 = {
      user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
      user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
    };

    var form = new FormData();
    form.append("user_type", data1.user_type);
    form.append("user_id", data1.user_id);
    form.append("amount", amount);

    var res = await addWalletTapWebAPI(form);
    var data = res.data;
    if (data.status == "1") {
      let wallet_pay_url = data.wallet_pay_url;
      wallet_pay_url = wallet_pay_url.replace(
        "https://rovemain.rovesolution.com",
        "https://www.riderove.com/dev_rove"
      );
      var win
      setTimeout(() => {
        // win = window.open(wallet_pay_url, "popup", "width=600,height=600");
        win = window.open(wallet_pay_url,'_blank');
      })
  
        var timer = setInterval(function () {
          if (win && win.closed) {
            clearInterval(timer);
            resetValue(amount)
          }
        }, 1000);

        setTimeout(()=>{
          resetValue(amount)
        },7000);
  
    /*   if(isSafari){
        setModalOpen(true);
        setIframeSrc(wallet_pay_url);
        window.open(wallet_pay_url, '_blank');
    }else {

      var win = window.open(wallet_pay_url, "popup", "width=600,height=600");
      var timer = setInterval(function () {
        if (win.closed) {
          clearInterval(timer);
          setSubmitDisable(false);
          get_profile_data();
          document.getElementById("other_amount").value = "";
          if (document.getElementById("amount_" + amount)) {
            document.getElementById("amount_" + amount).checked = false;
          }
        }
      }, 1000);
    } */



    } else {
      setSubmitDisable(false);
      console.log("Error : add wallet tap payment", data);
    }
  };

  function resetValue (amount) {
    setSubmitDisable(false);
    get_profile_data();
    document.getElementById("other_amount").value = "";
    if (document.getElementById("amount_" + amount)) {
      document.getElementById("amount_" + amount).checked = false;
    }
  }

  const fetchCustomer = async (e) => {
    if (userProfileData.user_profile.tap_customer_id != "") {
      // setIsLoading(true);

      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append("customer_id", userProfileData.user_profile.tap_customer_id);

      var res = await retrieveTapCustomerApi(form);
      var data = res.data;
      setCustomerObj(data);
      if (data.cards) {
        var cards = data.cards;
        cards.map((card) => {
          if (card.brand == "VISA") {
            card.brand_image = "/assets/imgs/icons/visa@2x.png";
          } else {
            card.brand_image = "/assets/imgs/icons/ic_mastercard-2.png";
          }
          return card;
        });
      } else {
        var cards = [];
      }
      setSavedCards(cards);

      setIsLoading(false);
    }
  };

  const selectAmountHandler = async (e) => {
    let amount = e.target.value;
    document.getElementById("other_amount").value = amount;
  };

  /* const amountTapPaymentHandler = async (e) => {
    let amount = e.target.value.trim();
    if (amount) {
      document.getElementById("amount_" + amount).checked = false;
    }
  }; */

  const amountTapPaymentHandler = async (e) => {
    let amount = e.target.value.trim();
    if (amount) {
      const element = document.getElementById("amount_" + amount);
      if (element) {
        element.checked = false;
      } else {
        console.warn(`Element with ID amount_${amount} not found.`);
      }
    }
  };

  const creditCardHandler = () => {
    if (savedCards.length > 0) {
      setForSavedCreditCardShow(true);
    } else {
      walletAddCreditCardHandler();
    }
  };

  const walletAddCreditCardHandler = () => {
    setForCreditCardShow(true);
   /*  let amount = document.getElementById("other_credit_amount").value;

    document.getElementById("other_credit_amount").value = "";
    if (document.getElementById("credit_amount_" + amount)) {
      document.getElementById("credit_amount_" + amount).checked = false;
    } */
  };

  const handleCardToken = async (token) => {
    try {
      if (token.status == "ACTIVE") {
        
        setIsLoading(true);
        setForCreditCardShow(false);
  
        var token_name = token.card.name
        var token_name_split = token_name.split(' ')
        var token_first_name = ''
        var token_middle_name = ''
        var token_last_name = ''
        if( token_name_split.length > 2 ) {
          token_first_name = token_name_split[0]
          token_middle_name = token_name_split[1]
          token_last_name = token_name_split[2]
        }
        else if( token_name_split.length > 1 ) {
          token_first_name = token_name_split[0]
          token_last_name = token_name_split[1]
        }
        
        var save_card_form = new FormData();
        // save_card_form.append("tap_secret", tap_secret);
        save_card_form.append("currency", customerObj.currency);
        save_card_form.append("threeDSecure", "1");
        save_card_form.append("save_card", "1");
        save_card_form.append("token", token.id);
        save_card_form.append("customer_id", customerObj.id);
        // save_card_form.append("first_name", customerObj.first_name);
        // save_card_form.append("middle_name", customerObj.middle_name);
        // save_card_form.append("last_name", customerObj.last_name);
        save_card_form.append("first_name", token_first_name);
        save_card_form.append("middle_name", token_middle_name);
        save_card_form.append("last_name", token_last_name);
        save_card_form.append("email", customerObj.email);
        save_card_form.append("country_code", customerObj.phone.country_code.replace('+',''));
        save_card_form.append("number", customerObj.phone.number);
        save_card_form.append("redirect_url", token.url);
        
        var save_card_res = await saveTapCardApi(save_card_form);
        // var save_card_data = save_card_res.data;
        
        var form = new FormData();
        // form.append("tap_secret", tap_secret);
        form.append("userId", userDetail.user_profile_id);
        form.append("user_type", userDetail.user_type);
        form.append("tap_card_id", token.card.id);
        form.append("tap_customer_id", userProfileData.user_profile.tap_customer_id);
        form.append("card_number", token.card.last_four);
        form.append("card_name", token.card.name);
        form.append("card_brand", token.card.brand);
        form.append("card_responce", "");
    
        var res = await addCardApi(form);
        // var data = res.data;
       var data = res.data;
        if (data.status == "1") {
          const new_card = data.data;
          let amount = document.getElementById("other_credit_amount").value;

          if (enableFeaturesList && enableFeaturesList.max_wallet_credit) {
            if ((parseFloat(amount) + parseFloat(userProfileData.user_profile.wallet)) > parseFloat(enableFeaturesList.max_wallet_credit)) {
              toast.error(`You can't add more than KD ${enableFeaturesList.max_wallet_credit} in your wallet.`, {
                position: "top-center",
                autoClose: 3000,
              });
              return false;
            }
          }
    
          document.getElementById("other_credit_amount").value = "";
          if (document.getElementById("credit_amount_" + amount)) {
            document.getElementById("credit_amount_" + amount).checked = false;
          }
    
          var formAddAmount = new FormData();
          formAddAmount.append("user_id", userDetail.user_profile_id);
          formAddAmount.append("user_type", userDetail.user_type);
          formAddAmount.append(
            "tap_customer_id",
            new_card.tap_customer_id
          );
          formAddAmount.append("tap_card_id", new_card.tap_card_id);
          formAddAmount.append("amount", amount);
    
          var res = await addCardWalletAPI(formAddAmount);
          var data = res.data;
          console.log(data);
          toast(data.message, {
            position: "top-center",
            autoClose: 3000,
          }); 
        } else {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 2000,
          }); 
       }
  
       // fetchCustomer()
       get_profile_data()
       setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  // form.append("tap_secret", "sk_test_LNRtwzjdyFM1UnEmuipT4rJv");

  const handleSaveCardChange = (data) => {
    console.log("onChangeSaveCardLater", data);
  };

  const get_profile_data = async () => {
    try {
      setIsLoading(true);
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      // console.log(res)
      var data = res.data;
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (forMeShow) {

      const input = controlref.current;
    iti = intlTelInput(input, {
      initialCountry: "KW",
      // showFlags:true,
      showSelectedDialCode: true,
      hiddenInput: function (telInputName) {
        return {
          phone: "phone_full",
          country: "country_code",
        };
      },
    });
    // input.value = inputPhoneValue;
    input.focus();
   var dialcode = iti.getSelectedCountryData().dialCode;
   setDialcode(dialcode)

    input.addEventListener("countrychange", function (e) {
      let dialcode = iti.getSelectedCountryData().dialCode;
      setDialcode(dialcode)
      // input.value = "+" + dialcode + " " + inputValue;
    });
    }
  }, [forMeShow]);

  const formHandleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    setFormDataWallet({ ...formDataWallet, [name]: value });

    if (name == "amount") {
      if (parseFloat(value) <= parseFloat(userProfileData.user_profile.wallet)) {
        setTransferButton(false);
      } else {
        setTransferButton(true);
      }
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const number = controlref.current.value;
      const amount = formDataWallet.amount;
      const message = formDataWallet.message;

      if (number.trim().length) {
        if (
          dialcode == 965 &&
          (!/[+0-9]{8}/.test(number) || number.trim().length != 8)
        ) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }
        if (
          dialcode == 91 &&
          (!/[+0-9]{10}/.test(number) || number.trim().length != 10)
        ) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }

        if (amount == "") {
          toast("Please, enter amount", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }
        
        var data1 = {
          user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
          user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
        };
  
        if (parseFloat(amount) > 0) {
          setTransferButton(true);
          var userForm = new FormData();
          userForm.append("mobile_no", "+" + dialcode + number);
          userForm.append("user_type", data1.user_type);
          userForm.append("user_id", data1.user_id);
          var checkUserRes = await checkUserNumberExistAppApi(userForm);

          var user = checkUserRes.data;
          if (user.status == "1") {
            user = user.data;
            
            let form = new FormData();
            form.append("user_type", data1.user_type);
            form.append("user_id", data1.user_id);
            form.append("amount", amount);
            form.append("message", message);
            form.append("transfer_user_id", user.user_profile_id);
            form.append("is_wallet", 1);
            
            var res = await transferWalletFreindKnetApi(form);
            var data = res.data;
            if (data.status == "1") {
              toast(data.message, {
                position: "top-center",
                autoClose: 2000,
              });
              setForMeShow(false);
              var update_amo = parseFloat(userProfileData.user_profile.wallet) - parseFloat(amount);

              setUserProfileData({
                ...userProfileData, user_profile: {
                  ...userProfileData.user_profile, wallet: update_amo.toFixed(3)
                }
              });
              wallet_history_v2_api();
              get_profile_data();
            } else {
              toast(data.message, {
                position: "top-center",
                autoClose: 2000,
              });
            }
          } else {
            toast(user.message, {
              position: "top-center",
              autoClose: 2000,
            });
          };
          setTransferButton(false);
        } else {
          toast("Please, enter valid amount", {
            position: "top-center",
            autoClose: 2000,
          });
        }
      } else {
        toast("Please, enter valid number", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      setTransferButton(false);

    } finally {
      setTransferButton(false);

      setIsLoading(false);
    }
  };

  const voucherSubmit = async (e) => {
    e.preventDefault();
    try {
      if (voucher.trim().length) {
        var data1 = {
          user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
          user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
        };

        var form = new FormData();
        form.append("user_type", data1.user_type);
        form.append("user_id", data1.user_id);
        form.append("coupon_code", voucher);

        var res = await redeemVoucher(form);
        var data = res.data;
        if (data.status == "1") {
          toast.success(data.message, {
            position: "top-center",
            autoClose: 2000,
          }); 
          setVoucher("");
          get_profile_data();
          wallet_history_v2_api();
        } else {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 3000,
          }); 
        }

      } else {
        toast.error("Please, enter valid coupon", {
          position: "top-center",
          autoClose: 2000,
        }); 
      }
    } catch (error) {
      console.log("voucherSubmit", error);
    }
  }

  const closeTapIfram = async () =>{
    setModalOpen(false);
  }

  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      
      <section className="section mt-40 " id="tax-page">
        <div className="container-fluid ">
          <div className="row">
            <div className=" col-md-3 col-lg-3"></div>
            <div className=" col-md-5 col-lg-5">
              {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center mb-5">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : <h3 className="text-20-bold text-center mb-5">
                Current Balance :{" "}
                { userProfileData?.user_profile.wallet
                  ? userProfileData.user_profile.wallet
                  : "Loading..."}{" "}
                KD
              </h3>}
              <div className="row">
                <div className="col-lg-12">
                  <div id="tax_profile_section">
                    <Tabs
                      defaultActiveKey="personal_tax"
                      id="tax-profile-tab"
                      className="mb-3"
                      fill
                    >
                      <Tab eventKey="personal_tax" title="KNET">
                        <div className="row">
                          <div className="col-md-12">
                            <div id="past-rides">
                              <h3 className="text-20-medium  mb-2">
                                Select your top up amount
                              </h3>

                              <form onSubmit={walletAddTapHandler}>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_10"
                                    placeholder=""
                                    value={10}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_10"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 10
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_20"
                                    placeholder=""
                                    value={20}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_20"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 20
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_30"
                                    placeholder=""
                                    value={30}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_30"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 30
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_50"
                                    placeholder=""
                                    value={50}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_50"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 50
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_100"
                                    placeholder=""
                                    value={100}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_100"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 100
                                  </label>
                                </div>
                                <div className="form-group my-3">
                                  <label
                                    for="other_amount"
                                    className="fw-bolder"
                                  >
                                    Other Amount
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="other_amount"
                                    placeholder="Enter amount"
                                    onClick={amountTapPaymentHandler}
                                  />
                                </div>
                                <div className="form-group text-center w-100">
                                  <button
                                    type="button"
                                    className="btn btn-primary w-30"
                                    onClick={walletAddTapHandler}
                                    disabled={submitDisable}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="business_tax" title="CREDIT CARD">
                        <h3 className="text-20-medium  mb-2">
                          Select your top up amount
                        </h3>

                        <form onSubmit={creditCardHandler}>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_10"
                              placeholder=""
                              value={10}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_10"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 10
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_20"
                              placeholder=""
                              value={20}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_20"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 20
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_30"
                              placeholder=""
                              value={30}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_30"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 30
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_50"
                              placeholder=""
                              value={50}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_50"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 50
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_100"
                              placeholder=""
                              value={100}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_100"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 100
                            </label>
                          </div>
                          <div className="form-group my-3">
                            <label
                              for="other_credit_amount"
                              className="fw-bolder"
                            >
                              Other Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="other_credit_amount"
                              placeholder="Enter amount"
                              onClick={amountCreditCardHandler}
                            />
                          </div>
                          <div className="form-group text-center w-100">
                            <button
                              type="button"
                              className="btn btn-primary w-30"
                              onClick={creditCardHandler}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Tab>
                      <Tab eventKey="voucher" title="VOUCHER">
                        <h3 className="text-20-medium mb-3">
                          Enter voucher code
                        </h3>
                        <form className="" method="post">
                          <div className="d-flex">
                            <div className="form-group w-100">
                              <label for="voucher" className="fw-bolder">
                                Voucher
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="voucher"
                                onChange={(e)=>setVoucher(e.target.value)}
                                value={voucher}
                                placeholder="Enter coupon code"
                              />
                            </div>
                          </div>

                          <div className="form-group text-center w-100">
                            <button
                              type="button"
                              className="btn btn-primary w-30"
                              onClick={voucherSubmit}
                            >
                              Validate
                            </button>
                          </div>
                        </form>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              {commonPending && commonPending.status =="0" &&
                <div className="form-group text-center w-100">
                <button
                  type="button"
                  className="btn btn-primary w-30"
                  onClick={() => setForMeShow(true)}
                >
                  WALLET TRANSFER
                </button>
              </div>}
              <div className="form-group text-center w-100">
                <button
                  className="btn btn-primary w-30"
                  type="button"
                  onClick={() => setHistoryModal(true)}
                >
                  History
                </button>
              </div>
            </div>
            <div className=" col-md-1 col-lg-1"></div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/icons/Vector@3x.png"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={isModalOpen}
        onHide={closeTapIfram}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Topup Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
          <iframe
              src={iframeSrc}
              style={{ width: "100%", height: "300px", border: "none" }}
              title="Wallet Payment"
            ></iframe>
          </div>
         {/*  <div className="mt-3">
            <div className="d-flex justify-content-center">
              <div className="button-group">
                <button
                  className="btn btn-card"
                  onClick={handleForSavedCreditCardClose}
                  style={{ marginRight: "30px" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={cardConfirmSelectHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
      <Modal show={forSavedCreditCardShow}
        onHide={handleForSavedCreditCardClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Credit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {savedCards.length > 0
              ? savedCards.map((card, index) => (
                  <div
                    className="card mb-3 card_select"
                    key={index}
                    onClick={(e) => cardSelectHandler(e, card)}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <img src={card.brand_image} alt="" height={30} />
                        <p className="mb-0">
                          <i>####</i>&nbsp;&nbsp;{card.last_four}
                        </p>
                        <p className="mb-0">
                          Expires in: {card.expiry.month}/{card.expiry.year}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
            <div
              className="card card_select"
              onClick={(e) => cardSelectHandler(e, "")}
            >
              <div className="card-body">
                <p className="mb-0">Top up with new card</p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-center">
              <div className="button-group">
                <button
                  className="btn btn-card"
                  onClick={handleForSavedCreditCardClose}
                  style={{ marginRight: "30px" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={cardConfirmSelectHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg"
        show={forCreditCardShow}
        onHide={handleForCreditCardClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: tap_public
            }}
            transaction={{
              reference: '',
              paymentAgreement: {
                id: '',
                contract: {
                  id: ''
                }
              }
            }}
            order={{
              id: '',
              amount: 1,
              currency: Currencies.KWD,
              description: '',
              reference: '',
              metadata: {}
            }}
            invoice={{
              id: ''
            }}
            customer={{
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                  last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                  middle: ''
                }
              ],
              nameOnCard: userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code.replace('+',''),
                  number: userProfileData?.user_profile.mobileno
                }
              }
            }}
            merchant={{
              id: ''
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: false,
                autoSaveCard: false,
              }
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true
              }
            }}
            acceptance={{
              supportedSchemes: ['VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
              supportedFundSource: ['CREDIT', 'DEBIT'],
              supportedPaymentAuthentications: ['3DS']
            }}
            post={{
              url: "https://tap.company"
            }}
            onReady={() => console.log('onReady')}
            onFocus={() => console.log('onFocus')}
            onBinIdentification={(data) => console.log('onBinIdentification', data)}
            onValidInput={(data) => setCreateEnabled(data)}
            onError={(data) => console.log('onError', data)}
            onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            // onChangeSaveCardLater={handleSaveCardChange}
            onSuccess={(data) => { handleCardToken(data) }}
            // onSuccess={(data) => console.log('on Success', data)}
          />
          <button className='btn mt-3 token_button w-100' onClick={tokenize} disabled={!createEnabled}>
          Save Card
          </button>
        </Modal.Body>
        {/* <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: tap_public,
            }}
            transaction={{
              reference: "transaction_01",
              paymentAgreement: {
                id: "",
                contract: {
                  id: "",
                },
              },
            }}
            order={{
              id: "",
              amount: 1,
              currency: Currencies.KWD,
              description: "",
              reference: "",
              metadata: {},
            }}
            invoice={{
              id: "invoice_01",
            }}
            customer={{
              // id: 'cus_TS01A1120241425Ju991206409',
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng
                    ? userProfileData?.user_profile.name_eng.split(" ")[0]
                    : "",
                  last: userProfileData?.user_profile.name_eng
                    ? userProfileData?.user_profile.name_eng.split(" ")[1]
                    : "",
                  middle: "",
                },
              ],
              nameOnCard: userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code,
                  number: userProfileData?.user_profile.mobileno,
                },
              },
            }}
            merchant={{
              id: "",
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true,
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: true,
                autoSaveCard: true,
              },
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true,
              },
            }}
            acceptance={{
              supportedSchemes: [
                "AMEX",
                "VISA",
                "MASTERCARD",
                "AMERICAN_EXPRESS",
              ],
              supportedFundSource: ["CREDIT", "DEBIT"],
              supportedPaymentAuthentications: ["3DS"],
            }}
            post={{
              url: "https://tap.company",
            }}
            onReady={() => console.log("onReady")}
            onFocus={() => console.log("onFocus")}
            onBinIdentification={(data) =>
              console.log("onBinIdentification", data)
            }
            onValidInput={(data) => setCreateEnabled(data)}
            onError={(data) => console.log("onError", data)}
            // onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            onChangeSaveCardLater={handleSaveCardChange}
            // onSuccess={(data) => console.log('onSuccess', data)}
            onSuccess={(data) => {
              handleCardToken(data);
            }}
          />
          <button
            className="btn mt-3 token_button w-100"
            onClick={tokenize}
            disabled={!createEnabled}
          >
            Save
          </button>
        </Modal.Body> */}
      </Modal>
      <Modal show={forMeShow} onHide={handleForMeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>WALLET TRANSFER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <p className="fs-6 fw-bold text-secondary mb-2 text-center">Your Balance</p>
            <h3 className="fs-4 fw-bold text-center mb-5">
              {userProfileData?.user_profile.wallet
                ? userProfileData.user_profile.wallet
                : "0"}{" "}
              <span style={{fontSize: "0.6em",verticalAlign: "sub",marginLeft: "2px",position: "relative",top: "-0.5em"}}>KD</span>
            </h3>
          </div>
          <div className="w-100">
          <div className="form-group my-3">
            <input
              type="number"
              min={0}
              className="form-control"
              placeholder="Enter amount"
              // ref={walletTransferAmountRef}
              onChange={formHandleChange}
              value={formDataWallet.amount}
              name="amount"
              // onClick={amountTapPaymentHandler}
            />
          </div>
          <div className="d-flex rider_section">
            <input
              type="tel"
              id="countrySelect"
              style={{width:"80%"}}
              placeholder="Recipient's number"
              maxLength={10}
              ref={controlref}
              // name="number"
              // value={formDataWallet.number}
              // onChange={formHandleChange}
              className={`form-control`}
            />
            </div>
            <div className="form-group my-3">
            <input
              type="text"
              name="message"
              className="form-control"
              placeholder="Message(Optional)"
              onChange={formHandleChange}
              value={formDataWallet.message}
              // ref={transferMessageRef}
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary w-100"
            variant="primary"
            type="button"
            onClick={formSubmitHandler}
            disabled={transferButton}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={historyModal} onHide={historyModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>WALLET HISTORY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex flex-column align-items-center"
            style={{ height: "500px", overflowY: "scroll" }}
          >
            {walletHistories &&
              walletHistories.map((h, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedHistory(h);
                    setHistoryModal(false);
                    setDetailHistoryModal(true);
                  }}
                  className="row my-2"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "7px",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <div className="col-md-8 col-sm-8 d-flex justify-content-end flex-column">
                    <p className="text-secondary mt-3 mb-2 fw-bold">{h.text}</p>
                    <p className="text-rove mb-2 fw-bold">{h.date}</p>
                  </div>
                  <div className="col-md-4 col-sm-4 d-flex align-items-center justify-content-end">
                    <span className="mx-2" style={{ fontSize: "17px" }}>
                      {" "}
                      KD
                    </span>
                    <p className="fs-4 fw-bold mb-0">
                      {parseFloat(h.debit) > 0 ? h.debit : h.credit}
                    </p>
                    <img src={h.src} alt="direction-down@2x" width={30} />
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary w-100"
            variant="primary"
            onClick={historyModalClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={detailHistoryModal}
        onHide={detailHistoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedHistory && (
            <div className="my-3">
              <div className="row my-2">
                {selectedHistory.transfer_user_name == "" ? (
                  <div className="col-md-12">
                    <p className="text-secondary  fw-bold">
                      {selectedHistory.text}
                    </p>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div className="col-md-5 col-sm-5 ">
                      <p className="text-secondary text-end mb-0 pt-2 fw-bold">
                        {parseFloat(selectedHistory.debit) > 0
                          ? historyStrings.debited_to
                          : historyStrings.credited_from}
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-7 ">
                      <p className="text-rove mb-0 pt-2 fw-bold">
                        {selectedHistory.transfer_user_name +" " +
                          `(${selectedHistory.transfer_contact})` +
                          " - " +
                          historyStrings.wallet_transfer}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="row mb-2">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between">
                  <p className="text-secondary mb-0 pt-2 fw-bold">Amount</p>
                  <div className="d-flex">
                    {" "}
                    <span className="mx-2 pt-2" style={{ fontSize: "17px" }}>
                      {" "}
                      KD
                    </span>
                    <p className="fs-4 fw-bold mb-0">
                      {parseFloat(selectedHistory.debit) > 0
                        ? selectedHistory.debit
                        : selectedHistory.credit}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between">
                  <p className="text-secondary mb-0 pt-2 fw-bold">
                    Date & Time
                  </p>
                  <p className="text-rove mb-0 pt-2 fw-bold">
                    {selectedHistory.date}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary w-100"
            variant="primary"
            onClick={detailHistoryModalClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default Wallet;
