import React from "react";
import { Link,useLocation } from "react-router-dom";
import Sidebar from "../common/Sidebar";


const Setting = () => {

  const location = useLocation()

  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <section className="section mt-40 mb-100 me-5" id="setting">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 ">
             <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 d-flex justify-content-center">

            <div className="col-md-8 col-lg-8 col-sm-12 col-12 mb-50" >
              <div className="row">
                <form>
                  {/* <div className="setting-form">
                    <label htmlFor="location">Location</label>
                    <select name="location" id="location">
                      <option value="India">India</option>
                      <option value="Chine">Chine</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="New York">New York</option>
                      <option value="London">London</option>
                    </select>
                  </div> */}
                  <div className="setting-form">
                    <label htmlFor="language">Language</label>
                    <select name="language" id="language">
                      <option value="English">English</option>
                      <option value="Arebic">Arabic</option>
                    </select>
                  </div>
                    <div className="mt-20">
                      <Link
                        className="btn btn-save-changes btn-primary d-flex justify-content-center align-items-center gap-3"
                        to="/dashboard"
                      >
                        Save changes
                      </Link>
                    </div>
                </form>
                {/* <div className="setting-app ">
                  <h6 className="mt-3">Authorized applications</h6>
                  <p>You do not have any authorized applications</p>
                </div> */}

                {/* <div className="mt-20">
                  <Link
                    className="btn btn-logout d-flex justify-content-center align-items-center gap-3"
                    to="/"
                  >
                    Logout
                  </Link>
                </div> */}
              </div>
            </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/icons/Vector@3x.png"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Setting;
