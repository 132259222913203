import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../common/Sidebar";
import MapSavedPlaces from "./MapSavedPlaces";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getFavPlaceApi, addFavPlaceApi, deleteFavPlaceApi, updateFavPlaceApi, nearSearchLocationApi } from "../../api/api"
import axios from "axios";
// import PreLoader from "../common/PreLoader";
import Spinner from "react-bootstrap/Spinner";

const custom_style = require("../../custom_style.json");

const optionsForFavouritePlace = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: true,
  draggable: true,
  scrollwheel: true,
  // defaultClickableIcons: true,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const SavedPlaces = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState({
    pick_up: "",
    destination: "",
  });
  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const Position = {
    lat: 29.3033426,
    lng: 47.9605811,
  }

  const [zoom, setZoom] = useState(12);
  const [pickupPosition, setPickupPosition] = useState(Position);
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [forMeShow, setForMeShow] = useState(false);
  const [favouriteID, setFavouriteId] = useState(0);
  const [favouriteName, setFavouriteName] = useState("");
  const [favPlace, setFavPlace] = useState()
  const [userDetail, setUserDetail] = useState()
  const [userProfileData, setUserProfileData] = useState({});
  const [addressType, setAddressType] = useState({ 1: true, 2: false, 3: false });
  const [addPlace, setAddPlace] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMap, setIsLoadingMap] = useState(false)
  const [showMap, setShowMap] = useState(false);
  const [title, setTitle] = useState();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const mapRef = useRef(null);
  const [currantPosition, setCurrantPosition] = useState();
  const [locationborder, setLocationBorder] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: ["KW"] },
  };
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [show, setShowCanvas] = useState(false);
  const handleForMeClose = () => setForMeShow(false);
  const handleClose = () => {
    setShowCanvas(false);
    setShowMap(false)
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const handleMapIdle = (lat, lng) => {
    console.log('check data in the handle map idle', lat, lng)
    near_search_location(lat, lng).then((data) => {
      const userLocation = data.user_location_data;
      setAddPlace({
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
      });
      setLocationName(userLocation.title_address);
    });
  };

  const showMapHandler = (e) => {
    e.preventDefault();
    setShowMap(true);
    fetchLocation();
  };

  const handleMapDrag = () => {
    setIsDragging(true);
    setLocationName('');
  };

  const handleMapDragEnd = () => {
    setIsDragging(false);
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      setAddPlace({ lat, lng });
      setLocationName(`Fetching address...`);

      near_search_location(lat, lng).then((data) => {
        const userLocation = data.user_location_data;
        setAddPlace({
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
        });
        setLocationName(userLocation.title_address);
      });
    }
  };

  const near_search_location = async (lat, lng) => {
    try {
      const data1 = {
        isFromDestination: 0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        latitude: lat ? lat : 0,
        longitude: lng ? lng : 0,
        title_adress: "",
        car_selection_id: 0,
      };
      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      const res = await nearSearchLocationApi(form);
      const data = res.data;
      if (data.status === "1") {
        return data;
      } else {
        console.log("near_search_location", data);
        return data;
      }
    } catch (error) {
      console.log("Error near_search_location", error);
    }
  };

  const handlePickupDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    near_search_location(lat, lng).then((data) => {
      var userLocation = data.user_location_data;

      var pic = document.querySelector("#Location1");
      pic.value = userLocation.title_address;
      var p = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        add: userLocation.title_address,
      };
      document.getElementById('place_address').value = p.add
      document.getElementById('place_lat').value = p.lat
      document.getElementById('place_long').value = p.lng
      setAddPlace(p);
    });
  };

  useEffect(() => {
    if (showMap) {
      fetchLocation();
    }
  }, [showMap]);


  // const fetchLocation = () => {
  //   setIsLoadingMap(true); // Start loading
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               var lat = position.coords.latitude;
  //               var long = position.coords.longitude;
  //               near_search_location(lat, long).then((data) => {
  //                 var userLocation = data.user_location_data;
  //                 var pic = document.querySelector("#Location1");
  //                 pic.value = userLocation.title_address;
  //                 document.getElementById('place_address').value = userLocation.title_address
  //                 document.getElementById('place_lat').value = userLocation.latitude
  //                 document.getElementById('place_long').value = userLocation.longitude
  //                 setAddPlace({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                 });
  //                 setLocationName(userLocation.title_address);
  //                 setIsLoadingMap(false);
  //               }).catch((error) => {
  //                 console.error("Error fetching near location:", error);
  //                 setIsLoadingMap(false);
  //               });
  //             },
  //             (error) => {
  //               console.error("Error getting geolocation:", error);
  //               setIsLoadingMap(false);
  //             },
  //             { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
  //           );
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //               var lat = position.coords.latitude;
  //               var long = position.coords.longitude;
  //               near_search_location(lat, long).then((data) => {
  //                 var userLocation = data.user_location_data;

  //                 var pic = document.querySelector("#Location1");
  //                 pic.value = userLocation.title_address;

  //                 setAddPlace({
  //                   lat: parseFloat(userLocation.latitude),
  //                   lng: parseFloat(userLocation.longitude),
  //                 });
  //                 setLocationName(userLocation.title_address);
  //                 setIsLoadingMap(false);
  //               }).catch((error) => {
  //                 console.error("Error fetching near location:", error);
  //                 setIsLoadingMap(false);
  //               });
  //             },
  //             (error) => {
  //               console.error("Error getting geolocation:", error);
  //               setIsLoadingMap(false);
  //             },
  //             { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
  //           );
  //           alert('Location permission issue "prompt"....');
  //         } else if (result.state === "denied") {
  //           alert('Location permission "denied"....');
  //           setIsLoadingMap(false);
  //         }
  //       }).catch((error) => {
  //         console.error("Error querying geolocation permissions:", error);
  //         setIsLoadingMap(false);
  //       });
  //   } else {
  //     alert("Sorry location not available!");
  //     setIsLoadingMap(false);
  //   }
  // };

  const fetchLocation = () => {
    const getCurrentLocation = () => {
      setIsLoadingMap(true); // Start loading
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;
  
          near_search_location(lat, long)
            .then((data) => {
              const userLocation = data.user_location_data;
  
              // Update form fields with fetched location data
              document.querySelector("#Location1").value = userLocation.title_address;
              document.getElementById("place_address").value = userLocation.title_address;
              document.getElementById("place_lat").value = userLocation.latitude;
              document.getElementById("place_long").value = userLocation.longitude;
  
              // Update state
              setAddPlace({
                lat: parseFloat(userLocation.latitude),
                lng: parseFloat(userLocation.longitude),
              });
              setLocationName(userLocation.title_address);
  
              setIsLoadingMap(false);
            })
            .catch((error) => {
              console.error("Error fetching near location:", error);
              setIsLoadingMap(false);
            });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setIsLoadingMap(false);
        },
        { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
      );
    };
  
    if (navigator.geolocation) {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
              getCurrentLocation();
            } else if (result.state === "denied") {
              alert('Location permission "denied"....');
            }
          })
          .catch((error) => {
            console.error("Error querying permissions:", error);
            // Fallback to directly getting the location
            getCurrentLocation();
          });
      } else {
        // Fallback if permissions API is not supported
        getCurrentLocation();
      }
    } else {
      alert("Sorry, geolocation is not available!");
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
  
        near_search_location(lat, long).then((data) => {
          const userLocation = data.user_location_data;
          const pic = document.querySelector("#Location1");
          
          setPickupPosition({
            lat: parseFloat(userLocation.latitude),
            lng: parseFloat(userLocation.longitude),
            // text: near_search_condition(data),
            add: userLocation.title_address,
          });
          setPosition({
            lat: parseFloat(userLocation.latitude),
            lng: parseFloat(userLocation.longitude),
          });
          setCurrantPosition({
            lat: parseFloat(userLocation.latitude),
            lng: parseFloat(userLocation.longitude),
            text: userLocation.title_address,
            add: userLocation.title_address,
          });
          setZoom(13);
  
          if (pic) {
            pic.value = userLocation.title_address;
          }
        });
      },
      (error) => {
        console.error("Error getting geolocation:", error);
      },
      { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
    );
  };
  
  const addPlaceHandler = (e) => {
    e.preventDefault()
    add_fav_place();
    setAddPlace({});
  }

  const handleOpenModal = (data) => {
    setForMeShow(true)
    setFavouriteId(data.favorite_id)
    setFavouriteName(data.name)
  }
  const DirectionsService = new google.maps.DirectionsService();

  const MapWithAMarker = withGoogleMap(({ location, handlePickupDrag, position }) => (
    <GoogleMap
      defaultZoom={zoom}
      options={optionsForFavouritePlace}
      center={location}
    >
      <>
        {location ? (
          <MarkerWithLabel
            position={location}
            icon="/assets/imgs/icons/pickupmarker.png"
            draggable={true}
            onDragEnd={handlePickupDrag}
            labelAnchor={new google.maps.Point(0, 0)}
          // labelStyle={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   backgroundColor: "#fff",
          //   fontSize: "15px",
          //   fontWeight: "700",
          //   padding: "10px",
          //   whiteSpace: "nowrap",
          //   transform: "translateX(-50%)",
          //   zIndex: "1",
          // }}
          >
            <div></div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
      </>

      {/* <TrafficLayer autoUpdate /> */}
    </GoogleMap>
  ));

  const aftersetDestinationPosition = (pickup, destination) => {
    if (pickup && destination) {
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(status);
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("ok", result);
            setRouteDirections(result);
            // setRequestRide(true);
          } else {
            console.log("err", result);
          }
          // console.log(routeDirections);
        }
      );
    }
  };


  useEffect(() => {
    const user = localStorage.getItem("user_data");
    if (user) {
      setUserDetail(JSON.parse(user));
    }
  }, []);

  useEffect(() => {
    get_saved_places()
  }, [userDetail])

  const get_saved_places = async () => {
    try {
      const data1 = {
        user_type: userDetail?.user_type || '', // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || '',
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.customer_id);
      setIsLoading(true)
      const res = await getFavPlaceApi(form);
      const data = res.data;
      setIsLoading(false)

      if (data.status == "1") {

        const updatedRequests = data.requests.map(async (req) => {
          const imageUrl = req.image_url;
          let updatedReq = { ...req };

          try {
            await axios.get(imageUrl); // Attempt to fetch the image
          } catch (error) {
            if (error.response && error.response.status === 404) {
              const updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
              updatedReq.image_url = updatedUrl;
            }
          }
          // var url = req.image_url;
          // const response = await fetch(url, { method: 'get' });

          // if (response && response.status === 404) {
          //   const updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
          //   console.log(updatedUrl)
          //   updatedReq.image_url = updatedUrl;
          // }

          return updatedReq;
        });
        Promise.all(updatedRequests).then((updatedData) => {
          data.requests = updatedData;
          setFavPlace(data);
        });
        setIsLoading(false)

        // data.requests.map((req)=>{
        //   let config = {
        //     method: 'get',
        //     maxBodyLength: Infinity,
        //     url: req.image_url,
        //     headers: { }  
        //   };

        //   axios.request(config)
        //   .then((response) => {
        //   })
        //   .catch((error) => {   
        //     if(error.response && error.response.status === 404){
        //       let image = req.image_url.replace("https://rovemain.rovesolution.com/","https://www.riderove.com/dev_rove/")
        //       req.image_url = image
        //     }
        //   });
        // })
        // setFavPlace(data)
        // console.log(data)dd
        // return false
      } else {
        setIsLoading(true)
        console.log("Error", data);
      }
    } catch (error) {
      console.log(error);
    }

  };

  const add_fav_place = async () => {
    try {
      // var address_type = 'Home';
      // if (addressType[1]) {
      //   address_type = 'Home';
      // }
      // if (addressType[2]) {
      //    address_type = 'Work';
      // }
      // if (addressType[3]) {
      // // address_type = document.getElementById('name1').value;
      // }
      
      var address_type = document.getElementById('name1').value;

   

      var latitute = document.getElementById('place_lat').value;
      var longitude = document.getElementById('place_long').value;
      var customAddress = document.getElementById('place_address').value;

      

      if (!latitute && !address_type) {
        // if (!toast.isActive("01", "saved_places")) {
        toast('The location & title field  is required.', {
          position: "top-center",
          type: "error",
          autoClose: 2000,
          closeOnClick: true,
        });
        // }
        fetchLocation()
        return;
      } 

      console.log(address_type)

      if (address_type.length <= 0) {
        // if (!toast.isActive("01", "saved_places")) {
        toast('The title field is required.', {
          position: "top-center",
          type: "error",
          autoClose: 2000,
          closeOnClick: true,
        });
        fetchLocation()
        // }
        return;
      }
   
      // console.log(latitute)
      const form = new FormData();
      form.append("user_type", userDetail.user_type || '');
      form.append("User_Id", userDetail?.user_profile_id || '');
      form.append("address_type", address_type)
      form.append("Name", address_type)
      form.append("Latitude", latitute)
      form.append('Longitude', longitude)
      form.append('Address', customAddress)
      form.append('title', customAddress)

      setIsLoading(true)
      setShowCanvas(false)

      const res = await addFavPlaceApi(form);
      setIsLoading(false)

      if (res.data.status == "1") {
        toast("Saved Place Successfully!", {
          position: "top-center",
          autoClose: 2000,
          type: "success",
        });
        setIsLoading(true)

        handleClose()
        get_saved_places();
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setIsLoading(true)
        console.log("Error while adding saved places:", res.data.message || "Unknown error");
      }
    } catch (error) {
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error adding saved places:", error.message);
    }
  };

  const update_saved_places = async () => {
    try {

      const form = new FormData();
      form.append("user_type", userDetail.user_type || '');
      form.append("user_id", userDetail.user_profile_id || '');
      form.append("favourite_title", favouriteName);
      form.append("favourite_id", favouriteID);

      const res = await updateFavPlaceApi(form);

      if (res.data.status == "1") {
        setIsLoading(true)
        toast("Place Title Updated Successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        get_saved_places();
        setForMeShow(false); // Close the modal
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        console.log("Error updating saved places:", res.data.message || "Unknown error");
      }
    } catch (error) {
      setIsLoading(true)
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error updating saved places:", error.message);
    }
  };

  const delete_saved_places = async () => {
    try {

      const data1 = {
        user_type: userDetail?.user_type || '', // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || '',
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.customer_id);
      form.append("Favourite_Id", favouriteID)


      const res = await deleteFavPlaceApi(form);
      const data = res.data;
      if (data.status == "1") {
        toast("Saved Place Deleted Successfully..", {
          position: "top-center",
          autoClose: 2000,
        });

        get_saved_places();
        setForMeShow(false);
        console.log('delete Successfully')
      } else {
        toast("Error While Deleting Saved Place..", {
          position: "top-center",
          autoClose: 2000,
        });
        console.log("Error", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  var dp = {
    lat: 29.3033426,
    lng: 47.9605811,
    text: "Emam Al Hassan Bin Ali St",
  };
  var pp = { lat: 29.3695727, lng: 47.9690211, text: "Liberation Tower" };

  useEffect(() => {
    var pickup = localStorage.getItem("pickupPosition");
    var destination = localStorage.getItem("destinationPosition");
    var pp = { lat: 29.2574643, lng: 47.9404039, text: "Liberation Tower" };
    // var pp = JSON.parse(pickup);
    setPickupPosition(pp);
    setPosition(pp);
    setZoom(12);

    var dp = {
      lat: 29.3033426,
      lng: 47.9605811,
      text: "Emam Al Hassan Bin Ali St",
    };
    // var dp = JSON.parse(destination);
    setDestinationPosition(dp);
    aftersetDestinationPosition(pp, dp);
  }, []);

  const pickupAddressChange = (e) => {
    if (e.target.value == "") {
      setAddPlace();
    }
  };

  setTimeout(function () {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    );

    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/

    autoCompleteRef.current.addListener("place_changed", async function (e) {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      var address = place.address_components;
      // console.log(place)
      // place.address_components.map(((data) => {
      //     data.long_name
      // }))
      let custom_address = place.name
      address.map((data) => (
        custom_address += ', ' + data.long_name
      ))

      near_search_location(lat, lng).then((res) => {
        var userLocation = res.user_location_data;
        custom_address = userLocation.title_address;
      });


      document.getElementById('place_address').value = custom_address
      document.getElementById('place_lat').value = lat
      document.getElementById('place_long').value = lng



      // console.log(place.address_components)
      // return false;
      // console.log(lat)
      var latlng = { lat: lat, lng: lng, text: place.name };
      setAddPlace(latlng);
    });
  }, 100);

  return (
    <>
      <main className="main">

        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <section className="section mt-40 mb-100" id="saved-palces">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-1 col-lg-1 "></div>
              <div className="col-md-2 col-lg-2 mb-50 order-sm-2 order-md-1 ">
                <Sidebar pathname={location.pathname}></Sidebar>
              </div>
              <div className="col-md-6 col-lg-6 px-5 order-md-2 order-sm-1 ">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-between mb-30">
                    <h2 style={{ fontWeight: "600" }}>My Places</h2>
                    <i className="bi bi-plus" onClick={() => setShowCanvas(true)}></i>
                  </div>


                  {
                    isLoading ? (
                      <div className="d-flex justify-content-center align-items-center mt-5 ">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) :
                      favPlace?.requests.length > 0 ? favPlace?.requests.map((data) => (
                        <div className="_css-imdnMt mb-5">
                          {/* <MapSavedPlaces
                      containerElement={<div style={{ height: `30vh` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      
                      location={dp}
                    /> */}
                          <img
                            style={{ height: '200px' }}
                            src={data.image_url} />

                          <div className="_css-cAseJQ d-flex justify-content-between">
                            <div className="d-flex w-70">
                              <div className="mx-3">
                                <h6 className="fw-bold">{data.name}</h6>
                                <p className="fw-bold">
                                  {data.address}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end w-30 mx-3">
                              <div>
                                <button
                                  className="btn py-1 ms-3 "
                                  onClick={() => { handleOpenModal(data) }}
                                >
                                  <i className="bi bi-three-dots-vertical"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )) : favPlace?.requests.length < 0 && <div><h5 style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 100,
                        textAlign: "center"
                      }}>Add Your Favourite Places.</h5></div>}
                </div>
              </div>
              <div className="col-md-3 col-lg-3 px-5 order-3 trip-last-section">
                <img
                  // src="/assets/imgs/icons/my-trip-2.svg"
                  src="/assets/imgs/icons/Vector@3x.png"
                  alt="my-trip-2"
                  className="mb-3"
                />
                <h6>Get a ride in minutes</h6>
                <p>
                  Book an Rove from a web browser, no app install necessary.
                </p>
                <div className="mt-20 w-70">
                  <Link
                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                    to="/dashboard"
                  >
                    Request a Ride
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal show={forMeShow} onHide={handleForMeClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update place title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="" method="post">
              <input
                type="hidden"
                id="favourite_id"
                name="favourite_id"
                value={favouriteID}
              />
              <div className="form-group">
                <label for="name" className="fw-bolder mb-10">
                  Title
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={favouriteName}
                  onChange={(e) => setFavouriteName(e.target.value)}
                  placeholder="Enter your title"
                />
              </div>
              <div className="form-group text-center w-100">
                <button
                  type="button"
                  className="btn btn-primary w-30"
                  style={{ padding: "0.3rem 0.75rem" }}
                  onClick={update_saved_places}
                >
                  Rename
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger w-30 mx-3"
                  onClick={delete_saved_places}
                >
                  Delete
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Offcanvas show={show} onHide={handleClose} id="SavedPlacescanvas" placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Place</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form id='add_place_form'>
              <input type="hidden" name="lat" id="place_lat" />
              <input type="hidden" name="long" id="place_long" />
              <input type="hidden" name="address" id="place_address" />
              <div className="search-item search-from mb-3" id="location-pickup">
                <label>
                  <input
                    type="text"
                    className="form-control" 
                    
                    style={{ border:locationborder ? '2px solid red' : '' }}
                    id="Location1"
                    placeholder="Enter location"
                    ref={inputRef}
                    // name="pickup"
                    onChange={pickupAddressChange}
                  />
                </label>
              </div>
              <div className="row addfavplace d-flex justify-content-evenly">
                {/* <div className="col-md-4 col-lg-4 ">
                  {" "}
                  <div
                    className={`d-flex  align-items-center justify-content-evenly ${addressType[1] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: true,
                        2: false,
                        3: false,
                      })
                    }
                  >
                    <i class="bi bi-house"></i>
                    Home
                  </div>{" "}
                </div>
                <div className="col-md-4 col-lg-4 ">
                  <div
                    className={`d-flex align-items-center justify-content-evenly ${addressType[2] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: false,
                        2: true,
                        3: false,
                      })
                    }
                  >
                    <i class="bi bi-briefcase"></i>
                    Work
                  </div>
                </div> */}
                {/* <div className="col-md-4 col-lg-4 ">
                  <div
                    className={`d-flex align-items-center justify-content-evenly ${addressType[3] ? "select" : ""
                      }`}
                    onClick={() =>
                      setAddressType({
                        1: false,
                        2: false,
                        3: true,
                      })
                    }
                  >
                    <i class="bi bi-star"></i>
                    Other
                  </div>
                </div> */}
              </div>
              {/* {addressType[3] && */}
              {addPlace &&
              <div className="form-group mb-3 mt-1">
                <input
                  style={{ background: "#eee" }}
                  type="text"
                  className="form-control"
                  id="name1"
                  placeholder="Enter your title"
                />
              </div>
              }
              {/* } */}


              {showMap && (
                <div className="mt-20">
                  {isLoadingMap ? (
                    <div className="d-flex justify-content-center align-items-center mt-5" style={{ height: '50vh' }}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <MapWithAMarker
                      containerElement={<div style={{ height: `50vh` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      // location={addPlace ? addPlace : Position}
                      location={addPlace}
                      // disabled={isDragging}
                      // handleMapIdle={handleMapIdle}
                      options={optionsForFavouritePlace}
                      handlePickupDrag={handlePickupDrag}
                    />
                  )}
                </div>
              )}

              <div className="my-3">
                <button type="button" className="btn btn-primary" onClick={addPlaceHandler}>Add Place</button>
                {!showMap &&
                  <button type="button" className="btn btn-primary mx-2" onClick={showMapHandler}>Add Place From Map</button>
                }
              </div>

            </form>

          </Offcanvas.Body>
        </Offcanvas>
      </main >
    </>
  );
};

export default SavedPlaces;
