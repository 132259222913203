import { useEffect } from "react";
import { useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
const custom_style = require("../../uber_style.json");

const options = {
  mapTypeId: "roadmap",
  disableDefaultUI: true,
  zoomControl: false,
  // defaultClickableIcons: false,
  clickableIcons: false,
  styles: custom_style,
  gestureHandling: "greedy",
};


const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const google = window.google;

const MapWithAMarker = withGoogleMap(
  ({
    driverArrive,
    showRoute,
    destinationPosition,
    position,
    pickupPosition,
    zoom,
    showCar,
    showDestinationMarker,
    showPickupMarker,
    carPosition,
    routeDirections,
    rideFinished,
    dynamicCar
  }) => {
    const [map, setMap] = useState(null);
    const [zoomLocal, setZoomLocal] = useState(null);
    
    const [carMarker, setCarMarker] = useState(null);
    var url = "/assets/imgs/icons/black_car_marker.png";
    // Animate the car marker along the route
    // useEffect(() => {
    //   if (map && showRoute && routeDirections && carPosition) {
    //     const route = routeDirections.routes[0];
    //     const steps = route.legs[0].steps;
    //     let stepIndex = 0;
    //     let previousPositionIndex = 0;

    //     const moveCar = () => {
    //       const step = steps[stepIndex];
    //       const stepPath = step.path;
    //       const duration = 1000; // milliseconds
    //       const delta = 0.0001; // adjust as needed for smoother movement
    //       const numPositions = stepPath.length;

    //       const move = () => {
    //         if (previousPositionIndex < numPositions) {
    //           const newPosition = stepPath[previousPositionIndex];
    //           if (!carMarker) {
    //             setCarMarker(
    //               new google.maps.Marker({
    //                 position: newPosition,
    //                 map: map,
    //                 icon: {
    //                   url: "/assets/imgs/icons/black_car_marker.png",
    //                   scaledSize: new google.maps.Size(15, 30),
    //                 },
    //               })
    //             );
    //           } else {
    //             carMarker.setPosition(newPosition);
    //           }
    //           previousPositionIndex++;
    //           setTimeout(move, duration);
    //         } else {
    //           // Move to the next step if available
    //           stepIndex++;
    //           previousPositionIndex = 0;
    //           if (stepIndex < steps.length) {
    //             setTimeout(moveCar, duration);
    //           }
    //         }
    //       };

    //       move();
    //     };

    //     moveCar();
    //   }
    // }, [map, showRoute, routeDirections, carPosition]);
    useEffect(() => {
      if (map && carPosition) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(carPosition);
        // bounds.extend(destinationPosition);
        map.fitBounds(bounds);
      }
    }, [map, carPosition]);

    if (dynamicCar == "White") {
      url = "/assets/imgs/icons/white_car_marker.png";
    }
    if (dynamicCar == "Blue") {
      url = "/assets/imgs/icons/blue_car 1.png";
    }
    if (dynamicCar == "Gray") {
      url = "/assets/imgs/icons/grey_car 1.png";
    }
    if (dynamicCar == "Silver") {
      url = "/assets/imgs/icons/silver_car.png";
    }
    if (dynamicCar == "Dynamic") {
      var d = localStorage.getItem("dynamic_car");
      url = "https://www.riderove.com/dev_rove/uploads/" + d;
    }

    return (
      <GoogleMap
        // ref={(map) => setMap(map)}
        defaultZoom={zoomLocal ? zoomLocal : zoom}
        center={position}
        options={options}
      >
        <>
          {/* {console.log("destinationPosition",destinationPosition,showDestinationMarker)}
          {console.log("carPosition",carPosition,showCar)}
          {console.log("pickupPosition",pickupPosition,showPickupMarker)} */}
            {/* {console.log(url)} */}
          {showCar && (
            <MarkerWithLabel
              position={carPosition}
              cursor="hand"
              icon={{
                url: url,
                scaledSize: new google.maps.Size(15, 30),
              }}
              labelAnchor={new google.maps.Point(0, 0)}
              labelStyle={
                rideFinished
                  ? {
                      backgroundColor: "#fff",
                      fontSize: "15px",
                      fontWeight: "700",
                      padding: "12px",
                    }
                  : {}
              }
            >
              <div>{rideFinished ? "Arrived to destination" : ""}</div>
            </MarkerWithLabel>
          )}

          {showPickupMarker && pickupPosition !== undefined ? (
            // pickupPosition.text !== undefined &&
            // pickupPosition.text != ""
            <MarkerWithLabel
            position={pickupPosition}
            cursor="pointer"
            icon={{
              url: "/assets/imgs/icons/pickupmarker.png",
            }}
            labelAnchor={new google.maps.Point(0, 45)}
            labelStyle={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              background:"transparents",
              fontSize: "12px",
              fontWeight: "700",
              paddingBottom: "15px",
              transform: "translateX(-50%)",
              // borderRadius: "8px",
              // boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
              whiteSpace: "nowrap",
              position: "relative",
            }}
          >
            <div className="d-flex align-items-center justify-content-center" style={{ position: "relative", textAlign: "center", padding:"10px", background:"#fff", 
              boxShadow: "0px 2px 6px rgba(0,0,0,0.3)", }}>
              {pickupPosition.text.includes("min") &&
                <img
                  src="/assets/imgs/icons/Ellipse 3@2x.png"
                  alt="Ellipse 3"
                  width={15}
                  className="me-2"
                  />
                }
                {pickupPosition.text}
              {/* Triangle Pointer (Tail) */}
              <div
                style={{
                  position: "absolute",
                  bottom: "-14px", // Move down to attach to the box
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "0",
                  height: "0",
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderTop: "15px solid white",
                }}
              />
            </div>
          </MarkerWithLabel>
            // <MarkerWithLabel
            //   position={pickupPosition}
            //   cursor="hand"
            //   icon="/assets/imgs/icons/pickupmarker.png"
            //   labelAnchor={new google.maps.Point(0, 0)}
            //   labelStyle={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     backgroundColor: "#fff",
            //     fontSize: "15px",
            //     fontWeight: "700",
            //     padding: "10px",
            //     whiteSpace: "nowrap",
            //     transform: "translateX(-50%)",
            //     zIndex: "1",
            //   }}
            // >
            //   <div>

            //     {pickupPosition.text.includes("min") &&
            //     <img
            //       src="/assets/imgs/icons/Ellipse 3@2x.png"
            //       alt="Ellipse 3"
            //       width={15}
            //       className="me-2"
            //       />
            //     }
            //     {pickupPosition.text}
            //   </div>
            // </MarkerWithLabel>
          ) : (
            ""
          )}

          {showDestinationMarker && destinationPosition !== undefined ? (
            // &&destinationPosition.text !== undefined &&
            // destinationPosition.text != ""
             <MarkerWithLabel
                            position={destinationPosition}
                            cursor="hand"
                            icon="/assets/imgs/icons/destination.png"
                            // draggable={isDestiDragg}
                            // onDragEnd={handleDestinationDrag}
                            labelAnchor={new google.maps.Point(0, 45)}
                            labelStyle={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                              background:"transparents",
                              fontSize: "12px",
                              fontWeight: "700",
                              paddingBottom: "15px",
                              transform: "translateX(-50%)",
                              // borderRadius: "8px",
                              // boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                              whiteSpace: "nowrap",
                              position: "relative",
                            }}
                            >
                               <div style={{ position: "relative", textAlign: "center", padding:"10px", background:"#fff", 
                                boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                              }}>
                              <img
                  src="/assets/imgs/icons/ic_square_map.png"
                  alt="ic_square_map 3"
                  width={15}
                  className="me-2"
                  />
                {destinationPosition.text}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-14px", // Move down to attach to the box
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  width: "0",
                                  height: "0",
                                  borderLeft: "10px solid transparent",
                                  borderRight: "10px solid transparent",
                                  borderTop: "15px solid white",
                                }}
                              />
                                
                              </div>
                          </MarkerWithLabel>
            
            // <MarkerWithLabel
            //   position={destinationPosition}
            //   cursor="hand"
            //   icon="/assets/imgs/icons/destination.png"
            //   labelAnchor={new google.maps.Point(0, 0)}
            //   labelStyle={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     backgroundColor: "#fff",
            //     fontSize: "15px",
            //     fontWeight: "700",
            //     padding: "10px",
            //     whiteSpace: "nowrap",
            //     transform: "translateX(-50%)",
            //     zIndex: "1",
            //   }}
            // >
            //   <div>
            //   <img
            //       src="/assets/imgs/icons/ic_square_map.png"
            //       alt="ic_square_map 3"
            //       width={15}
            //       className="me-2"
            //       />
            //     {destinationPosition.text}</div>
            // </MarkerWithLabel>
          ) : (
            ""
          )}
        </>
        {showRoute && routeDirections && (
          <DirectionsRenderer
            directions={routeDirections}
            defaultOptions={{
              suppressMarkers: true,
              polylineOptions: { strokeColor: "#213d4a" },
            }}
          />
        )}

        {/*<TrafficLayer autoUpdate />*/}
      </GoogleMap>
    );
  }
);

export default MapWithAMarker;
