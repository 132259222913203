import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  callOtpApi,
  checkEmailApi,
  emailOtpApi,
  loginApi,
  enableFeaturesApi,
  otpApi,
  customerCommonPendingApi
} from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { UserContext } from "../../utils/userContext";
import { requestNotificationPermission } from "../../utils/notification";
import axios from "axios";

const Otp = () => {
  const itemsRef = useRef([]);
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [emailAskModal, setEmailAskModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(UserContext);
  const [deviceToken, setDeviceToken] = useState("fvUHPYgbEPRQNce-T6wLwO:APA91bGZq3JrXu5_-SPGGBwj55j3i6JL5Wh3DzzYWKm6zFuc7sBm1nMUXSr605zlfMwKBmmrvGBaYJD9v7NVrFq88gniQwiXiXZRppCZgCJBmUD8p5wAWwY");
  const [timer, setTimer] = useState(15)
  const [buttonHidden, setbuttonHidden] = useState(false)
  const [textDynamic, setDynamicText] = useState("")

  var auth = localStorage.getItem("auth-token");
  if (auth) {
    navigate("/dashboard");
  }

  const succesMessage = toast()
  const codeChangeHandler = async (event) => {
    var numberReg = new RegExp("[0-9]");
    if (!numberReg.test(event.target.value)) {
      event.target.value = "";
      return false;
    }


    const [, codeFieldIndex] = event.target.name.split("-");
    let fieldIntIndex = parseInt(codeFieldIndex, 10);

    setUserInput((prevState) => {
      const newInput = prevState.slice(0, fieldIntIndex) + event.target.value + prevState.slice(fieldIntIndex + 1);
      return newInput;
    });

    if (fieldIntIndex < 3) {
      itemsRef.current[fieldIntIndex + 1].focus();
    } else {
      // Check if all fields are filled
      const otp = itemsRef.current.map((ref) => ref.value).join("");
      if (otp.length === 4) {
        // Simulate form submission
        try {
          const formData = new FormData();
          formData.append("mobile_number", userNumber.split(" ")[1]);
          formData.append("user_type", 2);
          formData.append("device_token", deviceToken);
          formData.append("referral_code", "");
          formData.append("country_code", userNumber.split(" ")[0]);
          formData.append("otp", otp);
          formData.append("device_type", 2);

          setIsLoading(true);
          const res = await loginApi(formData);
          const data = res.data;
          setIsLoading(false);

          if (data.status === "1") {
            var user_data = data.userdata;
            // ctx.setLoginUser(user_data);
            ctx.setUserImage(user_data.profile_image_path);
            localStorage.setItem("auth-token", user_data.auth_token);
            localStorage.setItem("user_data", JSON.stringify(user_data));

            var user_type = user_data.user_type;
            var user_id = user_data.user_profile_id;

            get_enable_features_list(user_id, user_type).then(()=>{
              setUserInput("");
              navigate("/dashboard", { state: { user_data: user_data } });
            })
            await checkCommonPending(user_id, user_type);

            // toast.success("Login successfully", { position: "top-center", autoClose: 1500 });
          } else {
            setUserInput("");
            toast.error("Please enter valid OTP", { position: "top-center", autoClose: 2000 });
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    }
  };

  // const codeChangeHandler = (event) => {
  //   var numberReg = new RegExp("[0-9]");
  //   if (!numberReg.test(event.target.value)) {
  //     event.target.value = "";
  //     return false;
  //   }
  //   const [, codeFieldIndex] = event.target.name.split("-");
  //   let fieldIntIndex = parseInt(codeFieldIndex, 10);
  //   // console.log(fieldIntIndex, codeFieldIndex);
  //   // console.log(event.target.value);
  //   // setUserInput((prevState) => prevState + event.target.value);
  //   setUserInput((prevState) => {
  //     const newInput = prevState.slice(0, fieldIntIndex) + event.target.value + prevState.slice(fieldIntIndex + 1);
  //     return newInput;
  //   });
  //   if (fieldIntIndex < 3) {
  //     itemsRef.current[fieldIntIndex + 1].focus();
  //   }
  //   /* else {
  //     const field = document.querySelector(`Input[name=code-${fieldIntIndex}]`);
  //     field.blur();
  //   } */
  // };

  const checkCommonPending = async (user_id, user_type) => {
    try {
      var form = new FormData();
      // form.append("tap_secret", tap_secret);
      form.append('user_id', user_id);
      form.append('user_type', user_type);

      const res = await customerCommonPendingApi(form);
      const data = res.data;
      ctx.setCommonPending(data);
      return data;

    } catch (error) {
      console.log("checkCommonPending", error);
    }
  }

  const handleKeyDown = (event) => {
    const { key, target } = event;
    const [, codeFieldIndex] = target.name.split("-");
    let fieldIntIndex = parseInt(codeFieldIndex, 10);

    if (key === "Backspace") {
      if (target.value === "") {
        if (fieldIntIndex > 0) {
          itemsRef.current[fieldIntIndex - 1].focus();
          setUserInput((prevState) => prevState.slice(0, fieldIntIndex - 1) + prevState.slice(fieldIntIndex));
        }
      } else {
        target.value = "";
        setUserInput((prevState) => prevState.slice(0, fieldIntIndex) + prevState.slice(fieldIntIndex + 1));

      }
    }
  };

  const get_enable_features_list = async (user_id, user_type) => {
    try {
      var data1 = {
        user_type: user_type,
        user_id: user_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await enableFeaturesApi(form);
      var data = res.data;
      if (data.status == "1") {
        console.log('setenablefeatures')
        localStorage.setItem("enablefeatures", JSON.stringify(data.enable_features));
        ctx.setEnableFeaturesList(data.enable_features)
      } else {
        console.log("Error : get_enable_features_list", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const formSubmitHandler = async (e) => {
  //   e.preventDefault();
  //   try {
  //     var t = "";
  //     for (let i = 0; i < 4; i++) {
  //       var input = document.querySelector(`#otp-${i}`);
  //       t+= input.value;
  //     }
  //     const data1 = {
  //       mobile_number: userNumber.split(" ")[1],
  //       user_type: 2,
  //       device_token: deviceToken,
  //       referral_code: "",
  //       country_code: userNumber.split(" ")[0],
  //       otp: t,
  //       device_type: 2,
  //       // app_version:"59"
  //     };
  //     let form = new FormData();
  //     form.append("mobile_number", data1.mobile_number);
  //     form.append("user_type", data1.user_type);
  //     form.append("device_token", data1.device_token);
  //     form.append("referral_code", data1.referral_code);
  //     form.append("country_code", data1.country_code);
  //     form.append("otp", data1.otp);
  //     form.append("device_type", data1.device_type);
  //     setIsLoading(true);
  //     var res = await loginApi(form);
  //     var data = res.data;
  //     setIsLoading(false);
  //     if (data.status == "1") {
  //       var user_data = data.userdata;
  //       ctx.setLoginUser(user_data);
  //       localStorage.setItem("auth-token", user_data.auth_token);
  //       localStorage.setItem("user_data", JSON.stringify(user_data));
  //       var user_type = user_data.user_type
  //       var user_id = user_data.user_profile_id
  //       await get_enable_features_list(user_id, user_type)
  //       toast("Login successfully", {
  //         position: "top-center",
  //         autoClose: 2000,
  //       });
  //       setUserInput("");
  //       navigate("/dashboard", { state: { user_data: user_data } });
  //     } else {
  //       setUserInput("");
  //       toast("Please enter valid otp", {
  //         position: "top-center",
  //         autoClose: 2000,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    requestNotificationPermission()
      .then((token) => {
        setDeviceToken(token);
        console.log("Token received:", token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    let firstInput = document.querySelector("#otp-0");
    firstInput.focus();
    var email = localStorage.getItem("user_email");
    var number = localStorage.getItem("user_number");
    setOtp(otp);
    setUserEmail(email);
    setUserNumber(number);
  }, []);

  useEffect(() => {
    if (otp && otp.trim().length) {
      // setUserInput(otp);
      for (let i = 0; i < otp.length; i++) {
        const element = otp[i];
        var input = document.querySelector(`#otp-${i}`);
        // input.value = element;
      }
    }
  }, [otp]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setbuttonHidden(true)
      // setResendEnabled(true); // Enable resend option when timer ends
    }
  }, [timer]);

  const otpOnEmailClick = async () => {
    try {
      const data1 = {
        mobile_number: userNumber.split(" ")[1],
        country_code: userNumber.split(" ")[0],
      };
      var form = new FormData();
      form.append("mobile_number", data1.mobile_number);
      form.append("country_code", data1.country_code);
      setIsLoading(true);
      setDynamicText('email')
      setbuttonHidden(false)
      
      var res = await checkEmailApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        if (data.email.length > 0) {
          await otpOnEmailApi(data.email);
        } else {
          setEmailAskModal(true);
        }
        setTimer(15)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const otpOnEmailApi = async (email) => {
    try {
      const data1 = {
        mobile_number: userNumber.split(" ")[1],
        // mobile_number: 9974555909,
        email: email,
        device_token: deviceToken,
        device_type: 2,
      };

      var form = new FormData();
      form.append("mobile_number", data1.mobile_number);
      form.append("device_token", data1.device_token);
      form.append("device_type", data1.device_type);
      form.append("email", data1.email);
      setIsLoading(true);
      var res = await emailOtpApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setEmailAskModal(false);
        setEmailInputValue("");
      } else {
        toast(data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const emailInputSubmit = async (e) => {
    e.preventDefault();
    try {
      if (emailInputValue.trim().length) {
        await otpOnEmailApi(emailInputValue);
      } else {
        toast("Please, enter valid email", {
          position: "top-center",
          autoClose: 2000,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const codeInputFields = new Array(4).fill(0).map((item, index) => (
    <div
      className="col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xxl-3 otp-input"
      key={index}
    >
      <div className="form-group">
        <input
          // ref={(ref) => itemsRef.current.push(ref)}
          ref={(ref) => (itemsRef.current[index] = ref)}
          type="tel"
          name={`code-${index}`}
          id={`otp-${index}`}
          onChange={(event) => codeChangeHandler(event)}
          className="form-control"
          onKeyDown={handleKeyDown}
          maxLength={1}
          style={{ padding: "0.375rem 1.85rem" }}
        />
      </div>
    </div>
  ));

  const smsOtpHandler = async () => {
    try {
      const data1 = {
        mobile_number: userNumber.split(" ")[1],
        country_code: userNumber.split(" ")[0],
        device_token: deviceToken,
        is_whatsapp_otp: 0,
        device_type: 2,

      };
      setDynamicText('text')
      setbuttonHidden(false)
     
      var form = new FormData();
      form.append("mobile_number", data1.mobile_number);
      form.append("country_code", data1.country_code);
      form.append("device_token", data1.device_token);
      form.append("is_whatsapp_otp", data1.is_whatsapp_otp);
      form.append("device_type", data1.device_type);
      form.append("is_new_app", 1);
      setIsLoading(true);
      var res = await otpApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setTimer(15)
      } else {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  
  const whatsappOtpHandler = async () => {
    try {
      const data1 = {
        mobile_number: userNumber.split(" ")[1],
        country_code: userNumber.split(" ")[0],
        device_token: deviceToken,
        is_whatsapp_otp: 1,
        device_type: 2,

      };
      setDynamicText('whatsapp')
      setbuttonHidden(false)
     
      var form = new FormData();
      form.append("mobile_number", data1.mobile_number);
      form.append("country_code", data1.country_code);
      form.append("device_token", data1.device_token);
      form.append("is_whatsapp_otp", data1.is_whatsapp_otp);
      form.append("device_type", data1.device_type);
      form.append("is_new_app", 1);
      setIsLoading(true);
      var res = await otpApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setTimer(15)
      } else {
        toast(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const emailAskClose = () => {
    setEmailAskModal(false);
  };

  return (
    <main className="main">
      
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <section className="section mt-60 mb-100" id="otp">
          <div className="container-sub">
            <div className="box-login">
              <h4
                className="heading-44-otp wow fadeInUp mb-20"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                {
                  textDynamic == 'text' ? (
                    <>
                      Enter the OTP sent  <br /> as a {textDynamic} to {userNumber}
                    </>
                  ) : textDynamic == 'email' ? (
                    <>
                      Enter the OTP sent  <br /> as a {textDynamic} to <br /> {userEmail}
                    </>
                  ) : textDynamic == 'whatsapp' ? (
                    <>
                      Enter the OTP sent to you as a <br /> {textDynamic} message at  <br /> {userNumber}
                    </>
                  )
                   :  (
                    <>
                      Enter the OTP sent to <br /> {userNumber} or <br /> {userEmail}
                    </>
                  )
                }


                {/* Enter the OTP sent to <br /> {userNumber} or
                <br /> {userEmail} */}
              </h4>
              {/* <Link to={"/login"} className="forgot-number mb-4">
              Changed your mobile number?
            </Link> */}
              <div
                className="form-contact otp-form form-comment wow fadeInUp mt-30"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <form action="#"
                // onSubmit={formSubmitHandler}
                >
                  <div className="row">
                    {codeInputFields}
                    <div className="col-lg-12 mt-2">

                      <div className="mb-2">
                        {!buttonHidden &&
                          <>
                            <h5>Resend otp in:</h5>
                            <h5>00:{timer < 10 ? '0' : ''}{timer}</h5>
                          </>
                        }
                      </div>

                      {buttonHidden &&
                        <>
                          <h5 className="text-dark">Resend otp via:</h5>
                          <div className="d-flex justify-content-between">
                            <div className="mb-20 mx-1">
                              <button
                                type="button"
                                className="btn text-dark resend-otp"
                                onClick={smsOtpHandler}
                              >
                                <span ><img src="/assets/imgs/icons/call/call-user.png" className=" mb-1" /></span>
                                Number
                              </button>
                            </div>
                            <div className="mb-20 mx-1">
                              <button
                                type="button"
                                className="btn text-dark resend-otp"
                                onClick={whatsappOtpHandler}
                              // onClick={callOnOtpClick}
                              >
                                <span ><img src="/assets/imgs/icons/whatsapp/whatsapp.png" className=" mb-1" /></span>
                                Whatsapp
                              </button>
                            </div>
                            <div className="mb-20 mx-1">
                              <button
                                type="button"
                                onClick={otpOnEmailClick}
                                className="btn text-dark resend-otp"
                              >
                                <span ><img src="/assets/imgs/icons/mail/mail.png" className=" mb-1" /></span>
                                Email
                              </button>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                    <div className="col-lg-12 col-md-12 mt-30 d-flex justify-content-between">
                      <div className="mb-20">
                        <Link className="btn text-dark resend-otp" to="/login">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="mb-20">
                        {/* <button
                          className="btn text-dark resend-otp"
                          type="submit"
                        // onClick={formSubmitHandler}
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <Modal show={emailAskModal} onHide={emailAskClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter your email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <input
              type="email"
              name="emailInput"
              id="emailInput"
              onChange={(e) => setEmailInputValue(e.target.value)}
              value={emailInputValue}
              className="form-control mt-3 mb-5"
              placeholder="Enter your email"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={emailInputSubmit}
            >
              Send
            </button>
          </div>
        </Modal.Body>
        {/*  <Modal.Footer className="justify-content-between">
            <button className="btn btn-light w-45" onClick={emailAskClose}>
              Cancle
            </button>
            <button
              className="btn btn-primary w-45"
            >
              Done
            </button>
          </Modal.Footer> */}
      </Modal>
    </main>
  );
};

export default Otp;
