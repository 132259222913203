import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const google = window.google;

const Hero = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    pick_up: "",
    destination: "",
  });

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: "KW" },
  };
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const autoCompleteRef2 = useRef();
  const inputRef2 = useRef();
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    try {
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };


  const pickupAddressChange = (e) => {
    if (e.target.value == "") {
      setPickupPosition();
    }
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      setDestinationPosition({});
    }
  };

  setTimeout(function () {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    );

    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/

    autoCompleteRef2.current = new google.maps.places.Autocomplete(
      inputRef2.current,
      options1
    );

    autoCompleteRef.current.addListener("place_changed", async function (e) {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();

      var latlng = { lat: lat, lng: lng, text: place.name };
      setPickupPosition(latlng);
    });

    autoCompleteRef2.current.addListener("place_changed", async function () {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      // console.log(lat)
      // console.log(lng)
      var latlng = { lat: lat, lng: lng, text: place.name };
      // console.log(latlng)
      setDestinationPosition(latlng);
      document.querySelector(".btn-search").classList.add("fill");
    });
  }, 100);

  return (
    <section className="section banner-homepage6">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="container">
        <div className="banner-homepage7 pb-50">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="row justify-content-center">
                <div className="col-xxl-8 col-xl-10 col-lg-12 col-12">
                  <div className="box-banner-homepage7-left">
                    <h2 className="mb-30 wow fadeInUp hero-heading">
                      {t("homeLanding")}
                    </h2>
                    <p className="text-16 wow fadeInUp fw-bold">
                      Enter a Pickup and Dropoff location to book a ride.
                    </p>
                    <div className="box-search-ride box-search-ride-style-3">
                      <form
                        className="box-search-ride box-search-ride-style-3 wow fadeInUp"
                        onSubmit={handleFormSubmit}
                        method="post"
                      >
                        <div className="w-100">
                          <div className="box-form-search">
                            <div
                              className="search-item search-from "
                              id="location-pickup"
                            >
                              <label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Location1"
                                  placeholder="Pickup location"
                                  ref={inputRef}
                                  name="pickup"
                                  onChange={pickupAddressChange}
                                />
                              </label>
                            </div>
                            <div
                              className="search-item search-from "
                              id="location-destination"
                            >
                              <label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Location2"
                                  placeholder="Dropoff location"
                                  ref={inputRef2}
                                  name="destination"
                                  onChange={destinationAddressChange}
                                />
                              </label>
                            </div>

                            <div className="col-md-5 col-lg-5 col-12">
                              <div
                                className="search-item search-button mb-0 "
                                // style={{ maxWidth: "30%" }}
                              >
                                <button
                                  className="btn btn-search"
                                  type="submit"
                                >
                                  Request a Ride
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="box-banner-homepage7-right wow fadeInUp">
                {/* <img src="/assets/imgs/bg/rove_bg.jpg " alt="taxi" /> */}
                {/* <img src="assets/imgs/ride-image.png" alt="taxi" /> */}
                <img src="/assets/imgs/page/homepage1/mobile.png" alt="taxi" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
