import { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
  Circle,
} from "react-google-maps";

// } from "@react-google-maps/api";

const custom_style = require("../../uber_style.json");

const options = {
  mapTypeId: "roadmap",
  disableDefaultUI: true,
  zoomControl: true,
  // defaultClickableIcons: false,
  clickableIcons: false,
  styles: custom_style,
  gestureHandling: "greedy",
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const google = window.google;

const MapWithAMarker = withGoogleMap(
  ({
    markerLoader,
    handlePickupDrag,
    handleDestinationDrag,
    zoom,
    pickupPosition,
    position,
    destinationPosition,
    routeDirections,
    driverPositions,
    path,
    dynamicCar,
    fetchLocation,
    isPickupDragg,
    isDestiDragg,
    currantPosition
  }) => {
    // const [map, setMap] = useState(null);
    const mapRef = useRef(null);
    const [lastCenter, setLastCenter] = useState(null);
    useEffect(() => {
      if (path && mapRef.current) {
        const bounds = new google.maps.LatLngBounds();
        path.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
        mapRef.current.fitBounds(bounds);
      }
    }, [path]);
    useEffect(() => {
      if (mapRef.current) {
        const map =
          mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    
        const controlDiv = document.createElement("div");
        controlDiv.style.marginRight = "10px";
        controlDiv.style.display = "flex";
        controlDiv.style.borderRadius = "30px";
        controlDiv.style.background = "#fff";
        controlDiv.style.flexDirection = "column"; // Stack buttons vertically
        // controlDiv.style.gap = "5px"; // Add space between buttons
    
        // Function to create a control button
        const createControlButton = (imgSrc, onClick,cls) => {
          const controlUI = document.createElement("button");
    
          // Style the button
          controlUI.style.backgroundColor = "#ffffff";
          controlUI.style.border = "none";
          // controlUI.style.borderRadius = "50%"; // Circular button
          controlUI.style.padding = "10px";
          controlUI.style.cursor = "pointer";
          controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";
          controlUI.style.width = "40px"; // Adjust width
          controlUI.style.height = "40px"; // Adjust height
          controlUI.style.display = "flex";
          controlUI.style.alignItems = "center";
          controlUI.style.justifyContent = "center";
    
          // Create an img element
          const buttonImage = document.createElement("img");
          buttonImage.className = cls;
          buttonImage.src = imgSrc;
          buttonImage.alt = "Control Button";
          buttonImage.style.width = "20px"; 
          buttonImage.style.height = "20px"; 
          buttonImage.style.objectFit = "contain"; 
          buttonImage.style.filter = "grayscale(100%) brightness(5)"; 
    
          controlUI.appendChild(buttonImage);
          controlUI.addEventListener("click", onClick);
          return controlUI;
        };
        let trafficLayer = new google.maps.TrafficLayer();
        let trafficVisible = false; // State to track visibility
        const mapStyles = [
          {
            featureType: "all",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ];
    
        // Create Buttons
        const locateButton = createControlButton("/assets/imgs/icons/userLoc@2x.png", () => {
          var button = document.querySelector('.user_location').parentElement;
          if (button) {
            button.disabled = true;
          }
          console.log(button);
          fetchLocation(); 
          setTimeout(() => { 
            if (button) {
              button.disabled = false;
            }
          }, 5000);
        },'user_location');
        const zoomInButton = createControlButton("/assets/imgs/icons/globe.png",() => {
          var icon = document.querySelector(".globe");
          if (map.getMapTypeId() === "roadmap") {
            map.setMapTypeId("hybrid");
            map.setOptions({ styles: mapStyles });
            if (icon) {
              icon.style.filter = "none";
            }
          } else {
            if (icon) {
              icon.style.filter = "grayscale(100%) brightness(5)";
            }
            map.setMapTypeId("roadmap");
            map.setOptions(options);
          }
        },'globe');
        const zoomOutButton = createControlButton("/assets/imgs/icons/ic_carNew 1.png", () => {
          var icon = document.querySelector(".traffic");
          trafficVisible = !trafficVisible;
          if (trafficVisible) {
            trafficLayer.setMap(map); // Show Traffic
            if (icon) {
              icon.style.filter = "none";
            }
          } else {
            if (icon) {
              icon.style.filter = "grayscale(100%) brightness(5)";
            }
            trafficLayer.setMap(null); // Hide Traffic
          }
        },'traffic');
    
        // Append buttons to control div
        controlDiv.appendChild(locateButton);
        controlDiv.appendChild(zoomInButton);
        controlDiv.appendChild(zoomOutButton);
    
        // Position the buttons near the zoom controls
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
      }
    }, [mapRef]);
    // useEffect(() => {
    //   if (mapRef.current) {
    //     const map =
    //       mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

    //     const controlDiv = document.createElement("div");
    //     const controlUI = document.createElement("button");
    //     const controlUI2 = document.createElement("button");

    //     // Style the button
    //     controlUI.style.backgroundColor = "#ffffff"; // Remove default background
    //     controlUI.style.border = "none"; // Remove border
    //     controlUI.style.borderRadius = "10%"; // Make it circular (optional)
    //     controlUI.style.padding = "10px"; // Adjust padding to fit image
    //     controlUI.style.cursor = "pointer";
    //     controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";

    //     controlUI2.style.backgroundColor = "#ffffff"; // Remove default background
    //     controlUI2.style.border = "none"; // Remove border
    //     controlUI2.style.borderRadius = "10%"; // Make it circular (optional)
    //     controlUI2.style.padding = "10px"; // Adjust padding to fit image
    //     controlUI2.style.cursor = "pointer";
    //     controlUI2.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";

    //     // Create an img element
    //     const buttonImage = document.createElement("img");
    //     buttonImage.src = "/assets/imgs/icons/userLoc@2x.png"; // Path to your image
    //     buttonImage.alt = "Locate Me"; // Optional: Alt text for the image
    //     buttonImage.style.width = "20px"; // Set the width of the image
    //     buttonImage.style.height = "20px"; // Set the height of the image
    //     buttonImage.style.objectFit = "contain"; // Maintain aspect ratio

    //     // Append image to the button
    //     controlUI.appendChild(buttonImage);
    //     controlUI2.appendChild(buttonImage);

    //     controlDiv.appendChild(controlUI);
    //     controlDiv.appendChild(controlUI2);

    //     controlDiv.style.marginRight = "10px";
    //     controlDiv.style.displayy = "flex";

    //     // Add the button click event
    //     controlUI.addEventListener("click", fetchLocation);
    //     /* controlUI.addEventListener("click", () => {
    //       if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //           (position) => {
    //             const { latitude, longitude } = position.coords;
    //             map.panTo({ lat: latitude, lng: longitude });
    //           },
    //           (error) => {
    //             console.error("Error fetching location", error);
    //           }
    //         );
    //       } else {
    //         alert("Geolocation is not supported by this browser.");
    //       }
    //     }); */

    //     // Position the button near the zoom controls
    //     map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
    //   }
    // }, [mapRef]);

    const handleDragEnd = () => {
      if (mapRef.current) {
        if (!isPickupDragg && !isDestiDragg) {
          return;
        }
        const center = mapRef.current.getCenter();
        const newCenter = {
          lat: center.lat(),
          lng: center.lng(),
        };
        if (isPickupDragg) {
          handlePickupDrag(center.lat(), center.lng());
        } else if(isDestiDragg) {
          handleDestinationDrag(center.lat(), center.lng());
        }
      }
      // var centerMarker = document.querySelector('#centerMarker')
      // if (centerMarker) {
      //   centerMarker.style.display = 'none';
      // }
    };
    const handleZoomEnd = () => {
      
      if (mapRef.current) {
        if (!isPickupDragg && !isDestiDragg) {
          return;
        }
        const center = mapRef.current.getCenter();
        const newCenter = {
          lat: center.lat(),
          lng: center.lng(),
        };
        if (isPickupDragg) {
          handlePickupDrag(center.lat(), center.lng());
        } else if(isDestiDragg) {
          handleDestinationDrag(center.lat(), center.lng());
        }
      }
    };


  const handleIdle = () => {
    if (!mapRef.current || (!isPickupDragg && !isDestiDragg)) return;

    const center = mapRef.current.getCenter();
    const newCenter = { lat: center.lat(), lng: center.lng() };

    // ✅ Check if center has actually changed before updating
    if (
      !lastCenter ||
      lastCenter.lat !== newCenter.lat ||
      lastCenter.lng !== newCenter.lng
    ) {
      setLastCenter(newCenter); // Update last known center

      if (isPickupDragg) {
        handlePickupDrag(newCenter.lat, newCenter.lng);
      } else if (isDestiDragg) {
        handleDestinationDrag(newCenter.lat, newCenter.lng);
      }
    }
  };

    const handleDragStart = () => {
      var centerMarker = document.querySelector('#centerMarker')
      if (centerMarker) {
        centerMarker.style.display = 'inline';
      }
    }

    return (
      <div>
        {/* // onLoad={(map) => setMap(map)} */}
        <GoogleMap
          // defaultZoom={zoom}
          // onDragEnd={()=>{mapRef.current.getCenter().lat()}}
          onIdle={handleIdle} 
          // onIdle={handleZoomEnd} 
          // onDragStart={handleDragStart}
          onResize={handleDragEnd}
          onDragEnd={handleDragEnd}
          zoom={zoom}
          center={position}
          options={options}
          ref={mapRef}
        >
          <>
            {/* {console.log(typeof pickupPosition.lat)} */}
            {/* {console.log("MAP position",position)} */}
            {/* {console.log("zoom",zoom)}  */}
            {/* {console.log(path)} */}
            {driverPositions &&
              Array.isArray(driverPositions) &&
              driverPositions.map((v, i) => {
                var url = "/assets/imgs/icons/black_car_marker.png";
                if (v.car_color == "White") {
                  url = "/assets/imgs/icons/white_car_marker.png";
                }
                if (v.car_color == "Blue") {
                  url = "/assets/imgs/icons/blue_car 1.png";
                }
                if (v.car_color == "Gray") {
                  url = "/assets/imgs/icons/grey_car 1.png";
                }
                if (v.car_color == "Silver") {
                  url = "/assets/imgs/icons/silver_car.png";
                }
                if (v.car_color == "Dynamic") {
                  url =
                    "https://www.riderove.com/dev_rove/uploads/" + dynamicCar;
                }
                return (
                  <MarkerWithLabel
                    key={i}
                    position={{
                      lat: parseFloat(v.latitude),
                      lng: parseFloat(v.longitude),
                    }}
                    cursor="pointer"
                    icon={{
                      url: url,
                      scaledSize: new google.maps.Size(15, 30),
                      rotation: 90
                    }}
                    labelAnchor={new google.maps.Point(0, 0)}
                  >
                    <div></div>
                  </MarkerWithLabel>
                );
              })}
           
            {currantPosition && Object.keys(currantPosition).length !== 0 && 
            <>
            <Marker 
             position={{
                  lat: parseFloat(currantPosition.lat),
                  lng: parseFloat(currantPosition.lng),
                }}
                icon= {
                 { path: google.maps.SymbolPath.CIRCLE,
                  scale: 10,
                  fillOpacity: 0.6,
                  strokeWeight: 2,
                  fillColor: '#0087ef',
                  strokeColor: '#cee0e9',
                }}>
            </Marker>
            
           
            <Circle
            center={{
              lat: parseFloat(currantPosition.lat),
              lng: parseFloat(currantPosition.lng),
            }}
            radius={10} // Radius in meters
            options={{
              fillColor: "#cee0e9",
              fillOpacity: 0.5, // Less opacity
              strokeColor: "#5384ED",
              strokeOpacity: 0.3,
              strokeWeight: 2,
            }}
            />
            </>
            }
             {/* <svg
                  fill="red"  // Set the fill color to red
                  className="react-datepicker__triangle"
                  aria-hidden="true"
                  width="18"
                  height="16"
                  viewBox="0 0 16 16"
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    left: "97px",
                    transform: "rotate(180deg) translateY(-1px)",
                    bottom: "100%",
                  }}
                >
                  <path
                    clipPath="url(#:r36:)"
                    fill="red"  // Also set the fill color here to red
                    strokeWidth="3"
                    d="M0,0 H16 L8,8 Q8,8 8,8 Z"
                  ></path>
                  <path stroke="red" d="M0,0 H16 L8,8 Q8,8 8,8 Z"></path>  // Set stroke to red
                  <clipPath id=":r36:">
                    <rect x="-1" y="1" width="18" height="16"></rect>
                  </clipPath>
                </svg> */}
            
            {!isPickupDragg && !isDestiDragg && !markerLoader && pickupPosition !== undefined &&
            pickupPosition.text !== undefined ? (
              <MarkerWithLabel
                position={{
                  lat: parseFloat(pickupPosition.lat),
                  lng: parseFloat(pickupPosition.lng),
                }}
                cursor="pointer"
                icon={{
                  url: "/assets/imgs/icons/pickupmarker.png",
                }}
                labelAnchor={new google.maps.Point(0, 0)}
                labelStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  background:"transparents",
                  fontSize: "12px",
                  fontWeight: "700",
                  paddingTop: "15px",
                  transform: "translateX(-50%)",
                  // borderRadius: "8px",
                  // boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                  whiteSpace: "nowrap",
                  position: "relative",
                }}
              >
                <div style={{ position: "relative", textAlign: "center", padding:"10px", background:"#fff", 
                  boxShadow: "0px 2px 6px rgba(0,0,0,0.3)", }}>
                  {pickupPosition.text !== "" ? (
                    pickupPosition.text
                  ) : (
                    <Spinner animation="grow" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}

                  {/* Triangle Pointer (Tail) */}
                  <div
                    style={{
                      position: "absolute",
                      top: "-14px", // Move down to attach to the box
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "15px solid white",
                    }}
                  />
                </div>
              </MarkerWithLabel>
            ) : (
              ""
            )}

            {!isPickupDragg && !isDestiDragg && !markerLoader  && destinationPosition !== undefined &&
            destinationPosition.text !== undefined ? (
              <MarkerWithLabel
                position={destinationPosition}
                cursor="hand"
                icon="/assets/imgs/icons/destination.png"
                // draggable={isDestiDragg}
                // onDragEnd={handleDestinationDrag}
                labelAnchor={new google.maps.Point(0, 0)}
                labelStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  background:"transparents",
                  fontSize: "12px",
                  fontWeight: "700",
                  paddingTop: "15px",
                  transform: "translateX(-50%)",
                  // borderRadius: "8px",
                  // boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                  whiteSpace: "nowrap",
                  position: "relative",
                }}
                >
                   <div style={{ position: "relative", textAlign: "center", padding:"10px", background:"#fff", 
                    boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                  }}>
                {destinationPosition.text !== "" ? (
                  <div>{destinationPosition.text}</div>
                ) : (
                  <></>
                    )}
                  <div
                    style={{
                      position: "absolute",
                      top: "-14px", // Move down to attach to the box
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "15px solid white",
                    }}
                  />
                    
                  </div>
              </MarkerWithLabel>
            ) : null}
            {routeDirections && (
              <DirectionsRenderer
                directions={routeDirections}
                defaultOptions={{
                  suppressMarkers: true,
                  polylineOptions: { strokeColor: "#213d4a" },
                }}
              />
            )}
            {path && (
              <>
                <Polyline
                  path={path}
                  options={{
                    strokeColor: "#213d4a",
                    strokeOpacity: 1.0,
                    strokeWeight: 3,
                  }}
                />
                {/* <Polyline
            path={path.slice(8,13)}
            options={{
              strokeColor: '#213d4a',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              }}
              /> */}
              </>
            )}

            {/*<TrafficLayer autoUpdate />*/}
          </>
        </GoogleMap>
        <div
          className={ (isPickupDragg || isDestiDragg || markerLoader) ? 'd-block' : `d-none`}
          style={{
            position: "absolute",
            top: "48%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          {markerLoader ?
            <Spinner />
            :
            <img src={ isPickupDragg ? "/assets/imgs/icons/pickupmarker.png" : '/assets/imgs/icons/destination.png'} alt="Marker" id="centerMarker" />
          }
        </div>
      </div>
    );
  }
);

export default MapWithAMarker;
