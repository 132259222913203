import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { otpApi } from "../../api/api";
import Spinner from "react-bootstrap/Spinner";
import { requestNotificationPermission } from "../../utils/notification";
import moment from "moment-timezone";

function Login() {
  const navigate = useNavigate();
  const controlref = useRef();
  var timeZone = moment.tz.guess();
  let iti = null;
  const [showTelephone, setShowTelephone] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputPhoneValue, setInputPhoneValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deviceToken, setDeviceToken] = useState("fvUHPYgbEPRQNce-T6wLwO:APA91bGZq3JrXu5_-SPGGBwj55j3i6JL5Wh3DzzYWKm6zFuc7sBm1nMUXSr605zlfMwKBmmrvGBaYJD9v7NVrFq88gniQwiXiXZRppCZgCJBmUD8p5wAWwY");

  useEffect(() => {
    var auth = localStorage.getItem("auth-token");
    if (auth) {
      navigate("/dashboard");
    }
    requestNotificationPermission()
      .then((token) => {
        setDeviceToken(token);
        console.log("Token received:", token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      var email = inputValue;
      var number = controlref.current.value;
      var dialcode = 965;
      if (timeZone == "Asia/Calcutta") {
        dialcode = 91;
      }
      console.log(email);
      console.log(number);
      console.log(dialcode);

      if (number.trim().length) {
        dialcode = iti.getSelectedCountryData().dialCode;
        /* if (!(await isValid(number))) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          console.log("first")
          return false;
        } */
        if (dialcode == 965 && number.trim().length < 8) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }
        if (dialcode == 91 && !/[+0-9]{10}/.test(number)) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }


        try {
          const data1 = {
            country_code: `+${dialcode}`,
            device_token: deviceToken,
            // is_whatsapp_otp: dialcode == 965 ? 0 : 1,
            is_whatsapp_otp: 0,
            device_type: 2,
            device_udid: "6aa634e3cdec1228",
            mobile_number: number,
           
          };
          localStorage.setItem(
            "user_number",
            data1.country_code + " " + data1.mobile_number
          );
          let form = new FormData();
          form.append("country_code", data1.country_code);
          form.append("device_token", data1.device_token);
          form.append("is_whatsapp_otp", data1.is_whatsapp_otp);
          form.append("device_type", data1.device_type);
          // form.append("device_udid", data1.device_udid);
          form.append("mobile_number", data1.mobile_number);
          form.append("is_new_app", 1);
          setIsLoading(true);
          let res = await otpApi(form);
          var data = res.data;
          setIsLoading(false);
          if (data.status == "1") {
            // toast(data.otp_code, {
            //   position: "top-center",
            //   autoClose: 10000,
            // });
            localStorage.setItem("otp", data.otp_code);
            localStorage.setItem("user_email", data.email);
            navigate("/otp");
            return false;
          } else {
            setShowTelephone(false);
            toast(data.message, {
              position: "top-center",
              autoClose: 2000,
            });
            return false;
          }
          // console.log(res);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          return false;
        }
      }
      if (email.trim().length) {
        navigate("/contact-number", { state: { email } });
        return false;
      }
      toast("Please, enter email or number", {
        position: "top-center",
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  var numbers = /[+0-9]/;
  const handleChange = (e) => {
    var d = e.target.value;
    if (d == "") {
      setShowTelephone(false);
      setInputValue(e.target.value);
    } else {
      if (numbers.test(d[0])) {
        setShowTelephone(true);
        setInputPhoneValue(e.target.value);
      } else {
        setShowTelephone(false);
        setInputValue(e.target.value);
      }
    }
  };

  const handlePhoneChange = (e) => {
    // setInputPhoneValue(e.target.value);
    var d = e.target.value;
    // console.log(d,typeof(d),"hello");
    if (!numbers.test(d[0]) || d == "") {
      setShowTelephone(false);
      setInputValue(e.target.value);
      document.querySelector("#email").focus();
    }
  };

  useEffect(() => {
    // const input = document.querySelector("#countrySelect");
    const input = controlref.current;
    
    iti = intlTelInput(input, {
      initialCountry: timeZone == "Asia/Calcutta"  ? "IN":"KW",
      // showFlags:true,
      showSelectedDialCode: true,
      hiddenInput: function (telInputName) {
        return {
          phone: "phone_full",
          country: "country_code",
        };
      },
    });

    const emailInput = document.querySelector("#email");

    if (showTelephone) {
      setInputValue("");
      document
        .querySelector(".iti.iti--allow-dropdown")
        .classList.remove("d-none");
      // input.value = "+965" + " " + inputValue;
      input.value = inputPhoneValue;
      console.log(inputPhoneValue);
      controlref.current.addEventListener("input", handlePhoneChange);
      input.focus();
      input.addEventListener("countrychange", function (e) {
        let dialcode = iti.getSelectedCountryData().dialCode;
        // input.value = "+" + dialcode + " " + inputValue;
        input.value = controlref.current.value;
        console.log("inputPhoneValue", controlref.current.value);
      });
    } else {
      // iti.destroy()
      document
        .querySelector(".iti.iti--allow-dropdown")
        .classList.add("d-none");
      emailInput.value = inputValue;
      emailInput.focus();
      controlref.current.value = "";
    }
    return () => {
      if (iti) iti.destroy();
      if (controlref.current) {
        controlref.current.removeEventListener("input", handlePhoneChange);
      }
    };
  }, [showTelephone]);

  return (
    <main className="main">
      
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center vh-100 vw-100 z-2 position-absolute"
          style={{ backgroundColor: "rgba(255,255,255,0.6)" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <section className="section mt-80 mb-100">
        <div className="container-sub">
          <div className="text-center">
            <h4
              className="heading-44-medium wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              What's your phone number <br /> or email ?
            </h4>
          </div>
          <div className="box-login mt-40">
            <div
              className="form-contact form-comment wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <form onSubmit={formSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group focused">
                      <input
                        type="email"
                        onChange={handleChange}
                        id="email"
                        className={
                          !showTelephone
                            ? `form-control`
                            : `form-control d-none`
                        }
                        value={inputValue}
                        placeholder="Your email or phone number"
                      />
                      <input
                        type="text"
                        id="countrySelect"
                        onChange={handlePhoneChange}
                        inputMode="decimal"
                        maxLength={12}
                        className={
                          showTelephone ? `form-control` : `form-control d-none`
                        }
                        ref={controlref}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-20">
                      <div className="d-flex justify-content-between">
                        <label className="text-14 color-text">
                          By entering your number or email and clicking on
                          "Next" you agree to our{" "}
                          <Link to="/terms-condition">Term & Conditions</Link> .
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-primary fw-bold w-100"
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
