import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Modal, Button, Accordion } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import MapWithAMarker from "../common/MapWithMarker";
import ReverseTimer from "../common/ReverseTimer";
import TimeCount from "../common/TimeCount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Rating from "../common/Rating";
import {
  addFavPlaceApi,
  cancleRideRequestApi,
  getFavPlaceApi,
  getProfileDataApi,
  getRequestDriverApi,
  nearSearchLocationApi,
  removeRideCoupon,
  runningRideCoupon,
  userLocationRequestApi,
  updateFareRunningride,
  changeRiderequestRunningride,
  ratingApi,
  customerRidePreference,
} from "../../api/api";
import moment from "moment-timezone";
import { UserContext } from "../../utils/userContext";

const google = window.google;

const Reasons = [
  "Driver was late",
  "Driver behavior",
  "Driving too fast",
  "Cleanliness",
  "Professionalisam",
];
const initialReasons = {
  "Driver was late": false,
  "Driver behavior": false,
  "Driving too fast": false,
  Cleanliness: false,
  Professionalisam: false,
};

const cancel_reasons = [
  "I don’t need a ride anymore",
  "I want to change my booking details",
  "The car is not suitable",
  "I couldn’t contact the driver",
  "The driver isn’t moving",
  "The driver couldn’t find my location",
  "The driver is too far",
  "Other",
];

const initial_cancel_reasons = {
  "I don’t need a ride anymore": false,
  "I want to change my booking details": false,
  "The car is not suitable": false,
  "I couldn’t contact the driver": false,
  "The driver isn’t moving": false,
  "The driver couldn’t find my location": false,
  "The driver is too far": false,
  Other: false,
};

const RideDetail = ({ enableFeatures }) => {
  const navigate = useNavigate();
  const inputRef2 = useRef();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [requestId, setRequestId] = useState(() => {
    var id = localStorage.getItem("request_id");
    return id ? id : null;
  });
  const [userProfileData, setUserProfileData] = useState();
  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });

  const { carTypes, enableFeaturesList } = useContext(UserContext);

  const [couponCode, setCouponCode] = useState("");
  const [zoom, setZoom] = useState(13);
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [forMeShow, setForMeShow] = useState(false);

  const [closeConfirmShow, setCloseConfirmShow] = useState(false); // modal 1 cancle ride
  const [closeResoneShow, setCloseResoneShow] = useState(false); // modal 2 cancle ride reasons
  const [selectCancelReasone, setSelectCancelReasone] = useState(
    initial_cancel_reasons
  );

  const [prefrenceShow, setPrefrenceShow] = useState(false);
  const [conversation, setConversation] = useState("Don't Mind");
  const [temperature, setTemperature] = useState("Normal");
  const [doorAssistence, setDoorAssistence] = useState(false);
  const [prefNote, setPrefNote] = useState("");

  const [showPickupMarker, setShowPickupMarker] = useState(true);
  const [showDestinationMarker, setShowDestinationMarker] = useState(false);

  const [rideStart, setRideStart] = useState(false);

  const [rate, setRate] = useState(0);

  const [driverArrive, setDriverArrive] = useState(false);
  const [headingToDestination, setHeadingToDestination] = useState(false);
  const [rideFinished, setRideFinished] = useState(false);
  const [showCancel, setShowCancel] = useState(true); // button

  const [showPickupLable, setShowPickupLable] = useState(false);

  const [totalWaitingCharge, setTotalWaitingCharge] = useState();
  const [reverseTimeset, setReverseTimeSet] = useState(true);
  const [counterTimeSet, setCounterTimeSet] = useState(true);
  const [driverArrivedTime, setDriverArrivedTime] = useState();
  const [showWaitingCharge, setShowWaitingCharge] = useState(false);
  const [waitingCharge, setWaitingCharge] = useState(0.0);
  const [seconds, setSeconds] = useState(20); //599 - 10 minutes in seconds
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [showTimerForWait, setShowTimerForWait] = useState(false);
  const [defaultWaitTime, setDefaultWaitTime] = useState(10);

  const [showRideTiming, setShowRideTiming] = useState(false);
  const [showTotalTiming, setShowTotalTiming] = useState(false);
  const [showRoute, setShowRoute] = useState(true);

  const [showCar, setShowCar] = useState(true);

  const [rideStatus, setRideStatus] = useState("Accepted");

  const [estimatedprice, setEstimatedprice] = useState();

  const [rateing, setRateinf] = useState(false);
  const [rateReason, setRateReason] = useState(false);
  const [selectRateReasone, setSelectRateReasone] = useState(initialReasons);
  const [driverDetail, setDriverDetail] = useState();
  const [requestDetail, setRequestDetail] = useState();
  const [accepted, setAccepted] = useState(true);
  const [arrived, setArrived] = useState(true);
  const [startRide, setStartRide] = useState(true);
  const [finishedRide, setFinishedRide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [locationDriver, setLocationDriver] = useState();

  const [selectedReason, setSelectedReason] = useState(null); // Track selected reason

  const [favPlaces, setFavPlaces] = useState();
  const [combinePlaces, setCombinePlaces] = useState();
  const [searchDestination, setSearchDestination] = useState(false);
  const [recentPlaces, setRecentPlaces] = useState(() => {
    var rp = localStorage.getItem("recent-places");
    return rp ? JSON.parse(rp) : null;
  });

  const [rideChangeFirst, setRideChangeFirst] = useState(true);

  const [addressType, setAddressType] = useState({
    1: true,
    2: false,
    3: false,
  });
  const [show, setShow] = useState(false);
  const [addPlace, setAddPlace] = useState();
  const [savedPlaceValue, setSavedPlaceValue] = useState();

  const [showAllSavedPlaces, setShowAllSavedPlaces] = useState(false);

  const [favPlacesOriginal, setFavPlacesOriginal] = useState();

  const getDriverRef = useRef(null);

  /* const handleSelectReasone = (reason) => {
    // Set the selected reason to true and others to false

    const updatedReasons = {};
    Reasons.forEach((item) => {
      updatedReasons[item] = item === reason;
    });

    //  select multiple reasones

    // const updatedReasons = { ...selectRateReasone };
    // updatedReasons[reason] = !updatedReasons[reason]; // Toggle the selected reason

    setSelectRateReasone(updatedReasons);
  }; */

  const handleSelectReasone = (item) => {
    // Update all reasons to false, then set the clicked item to true
    setSelectRateReasone({
      ...Object.fromEntries(
        Object.entries(selectRateReasone).map(([key, value]) => [
          key,
          key === item ? true : false,
        ])
      ),
    });
    setSelectedReason(item); // Track selected reason
  };

  const handleCancelReasonChange = (reason) => {
    const updatedReasons = {};
    cancel_reasons.forEach((item) => {
      updatedReasons[item] = item === reason;
    });
    setSelectCancelReasone(updatedReasons);
  };

  const [carPosition, setCarPosition] = useState();

  const handleRatingClose = () => {
    setRateinf(false);
  };
  const handleRateReasonClose = () => {
    setRateReason(false);
  };

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: ["IN", "KW"] },
  };

  const DirectionsService = new google.maps.DirectionsService();
  const handleForMeClose = () => {
    setForMeShow(false);
    setCouponCode("");
  };
  const handlePrefrenceClose = () => setPrefrenceShow(false);
  const handleCloseConfirm = () => setCloseConfirmShow(false);
  const handleCloseResone = () => setCloseResoneShow(false);

  const handleCancleRide = () => {
    setCloseConfirmShow(false);
    setCloseResoneShow(true);
  };

  const handleCancleRideReasone = () => {
    var resone = Object.keys(selectCancelReasone).find(
      (key) => selectCancelReasone[key] == true
    );
    if (!resone) {
      toast("Please, select reason for cancel ride", {
        position: "top-center",
        autoClose: 1000,
      });
      return false;
    }
    setCloseResoneShow(false);
    cancle_riderequest(resone);
    setSelectCancelReasone(initial_cancel_reasons);

    // navigate("/dashboard");
  };

  const TimeZone = (time, format = "Do MMM YYYY, h:mm a") => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };

  useEffect(() => {
    if (showTimerForWait) {
      const timer = setInterval(() => {
        if (timerSeconds === 59) {
          setTimerMinutes((prevMinutes) => prevMinutes + 1);
          setTimerSeconds(0);
        } else {
          setTimerSeconds((prevSeconds) => prevSeconds + 1);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timerSeconds, showTimerForWait]);

  useEffect(() => {
    if (showWaitingCharge && !showTimerForWait) {
      const countdownTimer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(countdownTimer);
            console.log("Timer reached zero!");
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(countdownTimer);
    }
  }, [showWaitingCharge]);

  useEffect(() => {
    if (showWaitingCharge && carTypes) {
      const car = carTypes.Car_Types.find(
        (cr) => cr.car_type_id === driverDetail.car_type
      );
      const initialWC = parseFloat(car.waiting_charge).toFixed(3);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      var timeDiff = calculateTimeDifference(driverArrivedTime);
      // console.log(timeDiff);
      if (timeDiff.minutes < defaultWaitTime && reverseTimeset) {
        setSeconds(
          defaultWaitTime * 60 - (60 * timeDiff.minutes + timeDiff.seconds)
        );
        setReverseTimeSet(false);
      } else if (timeDiff.minutes > defaultWaitTime && counterTimeSet) {
        setSeconds(0);
        setTimerSeconds(timeDiff.seconds);
        setTimerMinutes(timeDiff.minutes - defaultWaitTime);
        setWaitingCharge(
          parseFloat(initialWC) * Math.floor(timeDiff.minutes / defaultWaitTime)
        );
        setCounterTimeSet(false);
        setShowTimerForWait(true);
      }

      if (!showTimerForWait && `0${minutes}:0${remainingSeconds}` === "00:00") {
        setWaitingCharge((prev) => prev + parseFloat(initialWC));
        setShowTimerForWait(true);
      }

      const arr = ["10:00", "20:00", "30:00", "40:00", "50:00", "59:59"];
      const currentTime = `${
        timerMinutes < 10 ? "0" + timerMinutes : timerMinutes
      }:${timerSeconds < 10 ? "0" + timerSeconds : timerSeconds}`;

      if (arr.includes(currentTime)) {
        setWaitingCharge((prev) => prev + parseFloat(initialWC));
      }
    }

    /*  const timeout = setTimeout(() => {
      setShowWaitingCharge(true);
    }, 2000); */

    // return () => clearTimeout(timeout);
  }, [
    showWaitingCharge,
    carTypes,
    timerMinutes,
    timerSeconds,
    seconds,
    showTimerForWait,
    driverDetail,
  ]);

  const timerCount = (
    <>
      {timerMinutes < 10 ? "0" + timerMinutes : timerMinutes}:
      {timerSeconds < 10 ? "0" + timerSeconds : timerSeconds}
    </>
  );

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const reverseTime = (
    <>
      0{minutes}:{remainingSeconds < 10 ? "0" : ""}
      {remainingSeconds}
    </>
  );

  const calculateTimeDifference = (givenTime, compare_time) => {
    var currentTime = new Date().toISOString();
    currentTime = new Date(currentTime).getTime();
    if (compare_time) {
      currentTime = new Date(compare_time.replace(" ", "T") + "Z").getTime();
    }
    givenTime = new Date(givenTime.replace(" ", "T") + "Z").getTime();

    const diff = currentTime - givenTime;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  /*  const calculateTimeDifference = (givenTime) => {
    var currentTime = new Date().toISOString();  
    console.log(currentTime);
    currentTime = new Date(currentTime).getTime()
    console.log(currentTime)
    givenTime = new Date(givenTime).getTime();
    const diff = currentTime - givenTime;
    console.log(diff);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  }; */

  useEffect(() => {
    /* if (driverDetail) {
      var r_s = parseInt(driverDetail.ride_status);
      var arriveal_time = parseInt(driverDetail.driver_arrived_time);

      if (r_s == 1 && accepted) {
        toast("Your ride has accepted", {
          position: "top-center",
          autoClose: 1000,
        });
        setAccepted(false);
      } else if (arriveal_time != 0 && arrived) {
        //   cancel
        toast("Your driver has arrived at your location", {
          position: "top-center",
          autoClose: 1000,
        });
        setArrived(false);
        setShowWaitingCharge(true);
      } else if (r_s == 3 && startRide) {
        toast("Your ride has started.\nRide, Relax & Reach", {
          position: "top-center",
          autoClose: 1000,
        });
        setShowCancel(false);
        setShowWaitingCharge(false);
        setStartRide(false);
        setShowRideTiming(true);
      } else if (r_s == 4 && finishedRide) {
        toast("Your ride is finished.\nThank you for using Rove", {
          position: "top-center",
          autoClose: 2000,
        });
        setShowWaitingCharge(false);
        setShowRideTiming(false);
        setShowTotalTiming(true);
        setFinishedRide(false);
      }
    } */

    if (locationDriver) {
      var f_r = locationDriver.is_finish_ride;
      var c_r = locationDriver.is_cancel_ride;
      var s_r = locationDriver.is_start_ride;
      var a_r = locationDriver.is_driver_arrived;
      var rideChange = parseInt(locationDriver.is_driver_change_byAdmin);
      if (rideChange == 1 && rideChangeFirst) {
        get_request_driver();
        setRideChangeFirst(false);
      }

      if (f_r == 1 && finishedRide) {
        // setRideStatus("Finished")
        toast("Your ride is finished.\nThank you for using Rove", {
          position: "top-center",
          autoClose: 2000,
        });
        setTimeout(() => {
          get_request_driver();
          setRateinf(true);
        }, 5000);
        setShowWaitingCharge(false);
        setShowRideTiming(false);
        setShowTotalTiming(true);
        setFinishedRide(false);
        setArrived(false);
        // console.log("driverDetail.ride_rating", driverDetail.ride_rating);
        /* if (driverDetail.ride_rating != '0.0') {
          setRateinf(false)
        } else {
          setRateinf(true)
        } */
        // navigate("/ride-reciept/" + requestId);
        // localStorage.removeItem("request_id");
      } else if (c_r == 1) {
        // setRideStatus("Canceled")
        setArrived(false);
      } else if (s_r == 1 && startRide) {
        // setRideStatus("Started")
        get_request_driver().then((data) => {
          var driverdetail = data.driverdetail;
          var startTimer = calculateTimeDifference(
            driverdetail.start_ride_time
          );
          setShowTimerForWait(true);
          setTimerMinutes(startTimer.minutes);
          setTimerSeconds(startTimer.seconds);
        });

        var timeDiff = calculateTimeDifference(
          locationDriver.driver_arrived_time,
          driverDetail.start_ride_time
        );
        if (timeDiff.minutes >= defaultWaitTime && timeDiff.seconds > 1) {
          var initialWC = 2.0;
          if (carTypes) {
            const car = carTypes.Car_Types.find(
              (cr) => cr.car_type_id === driverDetail.car_type
            );
            initialWC = parseFloat(car.waiting_charge).toFixed(3);
          }
          var hours =
            timeDiff.hours > 9 ? timeDiff.hours : "0" + timeDiff.hours;
          var sec =
            timeDiff.seconds > 9 ? timeDiff.seconds : "0" + timeDiff.seconds;
          var minut =
            timeDiff.minutes > 9 ? timeDiff.minutes : "0" + timeDiff.minutes;

          setTotalWaitingCharge({
            charge:
              parseFloat(initialWC) *
              Math.floor(timeDiff.minutes / defaultWaitTime),
            time: hours + ":" + minut + ":" + sec,
          });
        }
        toast("Your ride has started.\nRide, Relax & Reach", {
          position: "top-center",
          autoClose: 1000,
        });
        document.querySelector(".preference").classList.add("d-none");
        document.querySelector(".vr").classList.add("d-none");
        document.querySelector("#lasthr").classList.add("d-none");
        document
          .querySelector(".preference")
          .parentElement.classList.remove("justify-content-between");
        document.querySelector(
          ".preference"
        ).parentElement.style.justifyContent = "center";
        setShowCancel(false);
        setShowWaitingCharge(false);
        setStartRide(false);
        setShowRideTiming(true);

        setArrived(false);
      } else if (a_r == 1 && arrived) {
        // setRideStatus("Driver Arrived")
        toast("Your driver has arrived at your location", {
          position: "top-center",
          autoClose: 1000,
        });
        setArrived(false);
        setDriverArrivedTime(locationDriver.driver_arrived_time);
        setShowWaitingCharge(true);
      }
    }
  }, [locationDriver]);

  /*  useEffect(() => {
    var pickup = localStorage.getItem("pickupPosition");
    var destination = localStorage.getItem("destinationPosition");
    var pp = { lat: 29.2574643, lng: 47.9404039, text: "Liberation Tower" };
    if (pickup) {
      pp = JSON.parse(pickup);
    }
    setPickupPosition(pp);
    setPosition(pp);
    setZoom(13);

    var dp = {
      lat: 29.3033426,
      lng: 47.9605811,
      text: "Emam Al Hassan Bin Ali St",
    };
    if (destination) {
      dp = JSON.parse(destination);
    }
    setDestinationPosition(dp);
    aftersetDestinationPosition(carPosition, pp);

    let driverAccept = setTimeout(() => {
      toast("Your ride has accepted", {
        position: "top-center",
        autoClose: 1000,
      });
    }, 1000);
    let driverArrive = setTimeout(() => {
      toast("Your driver has arrived at your location", {
        position: "top-center",
        autoClose: 2000,
      });
      setDriverArrive(true);
      setShowWaitingCharge(true);
      setPosition(pp);
      setCarPosition(pp);
      setShowRoute(false);

      // aftersetDestinationPosition(
      //   {
      //     lat: 29.2540494,
      //     lng: 47.9664417,
      //   },
      //   pp
      // );
    }, 5000);
    let headingToDestination = setTimeout(() => {
      toast("Your ride has started.Ride, Relax & Reach", {
        position: "top-center",
        autoClose: 2000,
      });
      setShowCancel(false);
      setShowRoute(true);
      setCarPosition(pp);
      aftersetDestinationPosition(pp, dp);
      setShowWaitingCharge(false);
      setShowPickupMarker(false);
      setRideStart(true);
      setShowRideTiming(true);
      setShowDestinationMarker(true);
    }, 10000);
    let nearToDestination = setTimeout(() => {
      setCarPosition({
        lat: 29.3119792,
        lng: 48.015632,
      });
      aftersetDestinationPosition(
        {
          lat: 29.3119792,
          lng: 48.015632,
        },
        dp
      );
    }, 20000);
    let nearToDestination2 = setTimeout(() => {
      setShowPickupMarker(false);
      setCarPosition({
        lat: 29.2827585,
        lng: 47.9850926,
      });
      aftersetDestinationPosition(
        {
          lat: 29.2827585,
          lng: 47.9850926,
        },
        dp
      );
      setShowDestinationMarker(true);
    }, 25000);
    let rideFinished = setTimeout(() => {
      // setShowPickupMarker(false)
      toast("Your ride is finished.Thank you for using Rove", {
        position: "top-center",
        autoClose: 2000,
      });
      setShowDestinationMarker(false);
      var destination = localStorage.getItem("destinationPosition");
      setCarPosition(JSON.parse(destination));
      // aftersetDestinationPosition(dp,dp);
      setShowCar(false);
      setRideFinished(true);
      setShowTotalTiming(true);
      setShowRideTiming(false);
      setShowRoute(false);
    }, 30000);
    let hideCar = setTimeout(() => {
      // setRateinf(true)
      navigate("/ride-reciept");
    }, 32000);
  }, []); */

  useEffect(() => {
    if (userDetail) {
      get_profile_data();
      get_request_driver();
      if (!favPlaces) {
        fetchFavoriteLocations();
      }
    }
  }, [userDetail, requestId]);

  useEffect(() => {
    if (driverDetail) {
      user_location_request();
    }
    getDriverRef.current = setInterval(() => {
      if (requestId) {
        // get_request_driver();
        user_location_request();
      }
    }, 10000); // 30 seconds

    return () => {
      clearInterval(getDriverRef.current);
      // clearTimeout(time)
    };
  }, [driverDetail]);

  const addPlaceHandler = (e) => {
    e.preventDefault();
    add_fav_place();
    setAddPlace({});
  };

  const handleDestinationClose = () => {
    setSearchDestination(false);
    setCombinePlaces();
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      // setCombinePlaces()
      setDestinationPosition({});
    } else {
      // inputRef2.current.addEventListener('input',()=> handleInputChange(inputRef2.current.value));
    }

    setTimeout(() => {
      // console.log('dest htipo', inputRef2.current.value)
      if (inputRef2.current.value != "") {
        inputRef2.current.addEventListener("input", () =>
          handleInputChange(inputRef2.current.value)
        );
      } else {
        setCombinePlaces();
      }
    }, 700);
  };

  const handleSuggestedDestination = (e) => {
    selectedDestination(JSON.parse(e.target.value));
  };

  var favpls;
  var savedPlacesMap;
  if (favPlaces && favPlaces.requests.length > 0) {
    favpls = showAllSavedPlaces
      ? favPlaces.requests
      : favPlaces.requests.slice(0, 5);

    savedPlacesMap = favpls.map((sp, index) => (
      <div
        className="my-3 d-flex align-items-center"
        style={{ cursor: "pointer" }}
        key={index}
        onClick={() => {
          selectedDestination(sp);
        }}
      >
        <img
          src={
            sp.address_type == "Home"
              ? "/assets/imgs/icons/ic_home.png"
              : sp.address_type == "Work"
              ? "/assets/imgs/icons/ic_work.png"
              : "/assets/imgs/icons/star.png"
          }
          alt="search@2x"
          width={30}
        />
        <div className="mx-2">
          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6>
          <p className="ms-2 mb-0">{sp.address}</p>
        </div>
      </div>
    ));
  }

  const selectedDestination = async (data) => {
    /*  var des = document.querySelector("#Location2");
     des.value = data.address; */
    // des.value = data.address.split(",")[0];
    saveRecentPalces(data);
    handleDestinationClose();

    var des = document.querySelector("#Location2");
    //  des.value = userLocation.title_address;
    requestDetail.userdetails.destination_address = data.address;

    var update_fare_res = await update_far_runningride(
      data.latitude,
      data.longitude,
      data.address
    );

    if (update_fare_res.status == "1") {
      var change_ride_res = await change_riderequest_runningride(
        data.latitude,
        data.longitude,
        data.address
      );
      if (change_ride_res.status == "1") {
        var estP =
          parseFloat(change_ride_res.estimatedprice) -
          parseFloat(change_ride_res.discount_price);
        var est2 =
          parseFloat(change_ride_res.estimatedprice_second) -
          parseFloat(change_ride_res.discount_price_second);
        var base =
          parseFloat(change_ride_res.base_price) -
          parseFloat(change_ride_res.base_fare_discount);
        setEstimatedprice(estP.toFixed(3));
        get_request_driver();
      } else {
        toast.error(change_ride_res.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.error(update_fare_res.message, {
        position: "top-center",
        autoClose: 2000,
      });
    }

    /* {
      "status": "1",
      "message": "Success",
      "base_price": "1.000",
      "estimatedprice": "5.500",
      "base_fare_discount": "0.000",
      "estimatedprice_second": "5.500",
      "discount_price": "0.000",
      "discount_price_second": "0.000"
      } */
    var dp = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
      //  text: "KD " + esti_pr,
      add: data.address,
    };

    setDestinationPosition(dp);

    var time = setTimeout(() => {
      if ("favorite_id" in data) {
        document.getElementById("dest_save").src =
          "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("dest_save_icon").classList.add("disable");
      } else {
        document.getElementById("dest_save").src =
          "/assets/imgs/icons/Vector.png";
        document.getElementById("dest_save_icon").classList.remove("disable");
      }
    }, 100);
    /*  var p_r = await price_calculation(
       pickupPosition.lat,
       pickupPosition.lng,
       data.latitude,
       data.longitude
     );
     var  esti_pr =
       parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
     esti_pr = esti_pr.toFixed(3); */
    /* near_search_location(data.latitude, data.longitude).then((res) => {
      var userLocation = res.user_location_data;
    }); */
  };

  const near_search_location = async (lat, lng) => {
    try {
      var data1 = {
        isFromDestination: 0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        // latitude: pickupPosition ? pickupPosition.lat : position.lat,
        // longitude:pickupPosition ?  pickupPosition.lng : position.lng,
        latitude: lat,
        longitude: lng,
        title_adress: "",
        car_selection_id: 0,
      };
      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      var res = await nearSearchLocationApi(form);
      var data = res.data;
      if (data.status == "1") {
        return data;
      } else {
        console.log("near_search_location", data);
        return data;
      }
    } catch (error) {
      console.log("Error near_search_location", error);
    }
  };

  const handleClearRecentPlaces = () => {
    localStorage.removeItem("recent-places");
    setRecentPlaces();
  };

  const handleClose = () => {
    setShow(false);
    document.getElementById("dest_save").src = "/assets/imgs/icons/Vector.png";
  };

  const get_estimated_time_google = async (p, d) => {
    try {
      const origin = { lat: p.lat, lng: p.lng };
      const destination = { lat: d.lat, lng: d.lng };
      const request = {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: "bestguess",
        },
      };
      /*  var url = `https://maps.googleapis.com/maps/api/directions/json?origin=${p.lat},${p.lng}&destination=${d.lat},${d.lng}&departure_time=${now}&sensor=false&mode=DRIVING&key=AIzaSyDskRkPZZjm3j9Trw55LGhJEe4AOA1I_fg`;    // CORS Error
 
      var res = await axios.get(url);
      var data = res.data; */
      const time = await new Promise((resolve, reject) => {
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(request, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            const duration = result.routes[0].legs[0].duration_in_traffic.value; // in seconds
            // const duration = result.routes[0].legs[0].duration.text;
            resolve(duration);
          } else {
            console.error(`Directions request failed due to ${status}`);
            reject(`Directions request failed due to ${status}`);
          }
        });
      });
      return time;
    } catch (error) {
      console.error("get_estimated_time_google", error);
      return null;
    }
  };

  const next_billion_time = async (key, p, d) => {
    try {
      /* https://api.nextbillion.io/distancematrix/json?key=d4399c5378244bc28d2a6ad7a6298b13&origins=23.0926438,72.5304807&destinations=23.0785651,72.5287579&mode=car */

      var url = `https://api.nextbillion.io/distancematrix/json?key=${key}&origins=${p.lat},${p.lng}&destinations=${d.lat},${d.lng}&mode=car`;

      var res = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      var data = await res.json();
      if (data.status == "Ok") {
        var duration = data.rows[0].elements[0].duration.value;
        return duration;
      } else {
        return null;
      }
    } catch (error) {
      console.error("next_billion_time", error);
      return null;
    }
  };

  const update_far_runningride = async (d_lat, d_lng, d_add) => {
    try {
      var data1 = {
        car_type: driverDetail ? driverDetail.car_type : 17,
        destination_address: d_add ?? "",
        destination_latitude: d_lat ?? 0,
        destination_longitude: d_lng ?? 0,
        isFinishRide: 2, // default
        offer_price: "0.000", // default
        payment_take: 1, // default
        pickup_address: driverDetail ? driverDetail.pickup_address : "",
        pickup_latitude: driverDetail ? driverDetail.pickup_latitude : 0,
        pickup_longitude: driverDetail ? driverDetail.pickup_longitude : 0,
        promo_code: couponCode,
        ride_request_id: requestId,
        user_id: userDetail.user_profile_id,
        user_type: userDetail.user_type,
        waiting_charge: "0.000",
      };

      var form = new FormData();
      form.append("car_type", data1.car_type);
      form.append("destination_address", data1.destination_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("isFinishRide", data1.isFinishRide);
      form.append("offer_price", data1.offer_price);
      form.append("payment_take", data1.payment_take);
      form.append("pickup_address", data1.pickup_address);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("ride_request_id", data1.ride_request_id);
      form.append("user_id", data1.user_id);
      form.append("user_type", data1.user_type);
      form.append("waiting_charge", data1.waiting_charge);

      /* form.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      }); */
      var res = await updateFareRunningride(form);
      var data = res.data;
      return data;
    } catch (error) {
      console.log("update_far_runningride", error);
    }
  };

  const change_riderequest_runningride = async (d_lat, d_lng, d_add) => {
    try {
      var data1 = {
        current_user_id: userDetail.user_profile_id,
        current_user_type: userDetail.user_type,
        destination_address: d_add ?? "",
        destination_latitude: d_lat ?? 0,
        destination_longitude: d_lng ?? 0,
        driver_id: driverDetail ? driverDetail.driver_id : "",
        place_id: "",
        ride_request_id: requestId,
        user_type: userDetail.user_type,
      };

      var form = new FormData();
      form.append("current_user_id", data1.current_user_id);
      form.append("current_user_type", data1.current_user_type);
      form.append("destination_address", data1.destination_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("driver_id", data1.driver_id);
      form.append("place_id", data1.place_id);
      form.append("ride_request_id", data1.ride_request_id);
      form.append("user_type", data1.user_type);

      var res = await changeRiderequestRunningride(form);
      var data = res.data;
      return data;
    } catch (error) {
      console.log("change_riderequest_runningride", error);
    }
  };

  const saveRecentPalces = (data) => {
    if (!("favorite_id" in data)) {
      let rp = localStorage.getItem("recent-places");
      if (rp) {
        rp = JSON.parse(rp);
        rp = rp.filter(
          (pl) => pl.address.split(",")[0] !== data.address.split(",")[0]
        );
        rp.unshift(data);
        setRecentPlaces(rp);
        localStorage.setItem("recent-places", JSON.stringify(rp));
      } else {
        setRecentPlaces([data]);
        localStorage.setItem("recent-places", JSON.stringify([data]));
      }
    }
  };

  const add_fav_place = async () => {
    try {
      // var address_type = "Home";
      // if (addressType[1]) {
      //   address_type = "Home";
      // }
      // if (addressType[2]) {
      //   address_type = "Work";
      // }
      // if (addressType[3]) {
      //   address_type = document.getElementById("name1").value;
      // }
      var address_type = document.getElementById("name1").value;

      var latitute = document.getElementById("place_lat").value;
      var longitude = document.getElementById("place_long").value;
      var customAddress = document.getElementById("place_address").value;

      const form = new FormData();
      form.append("user_type", userDetail.user_type || "");
      form.append("User_Id", userDetail?.user_profile_id || "");
      form.append("address_type", address_type);
      form.append("Name", address_type);
      form.append("Latitude", latitute);
      form.append("Longitude", longitude);
      form.append("Address", customAddress);
      form.append("title", customAddress);

      const res = await addFavPlaceApi(form);

      if (res.data.status == "1") {
        toast("Saved Place Successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        handleClose();
        fetchFavoriteLocations();
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        console.log(
          "Error while adding saved places:",
          res.data.message || "Unknown error"
        );
      }
    } catch (error) {
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error adding saved places:", error.message);
    }
  };

  const addToSavePlace = (e, val) => {
    if (e.currentTarget.classList.contains("disable")) {
      return false;
    }
    setShow(true);
    if (val == 0) {
      //pickup
      setSavedPlaceValue(0);
      var timeout = setTimeout(() => {
        document.getElementById("pick_up_save").src =
          "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("place_address").value = pickupPosition.add;
        document.getElementById("place_lat").value = pickupPosition.lat;
        document.getElementById("place_long").value = pickupPosition.lng;
      }, 100);
      return () => clearTimeout(timeout);
    } else if (val == 1) {
      setSavedPlaceValue(1);
      var timeout = setTimeout(() => {
        document.getElementById("dest_save").src =
          "/assets/imgs/icons/Vector-fill.png";
        document.getElementById("place_address").value =
          destinationPosition.add;
        document.getElementById("place_lat").value = destinationPosition.lat;
        document.getElementById("place_long").value = destinationPosition.lng;
      }, 100);
      return () => clearTimeout(timeout);
    } else {
      setSavedPlaceValue();
    }
  };

  const handleInputChange = (inputval) => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const options = {
      input: inputval,
      fields: options1.fields,
      types: options1.types,
      componentRestrictions: options1.componentRestrictions,
    };

    if (inputval == "") {
      setCombinePlaces();
      // fetchFavoriteLocations();
      setFavPlaces(favPlacesOriginal);
      var rp = localStorage.getItem("recent-places");
      setRecentPlaces(JSON.parse(rp));
    } else {
      var filFav = favPlaces.requests.filter(
        (pl) =>
          pl.name.toLowerCase().includes(inputval.toLowerCase()) ||
          pl.address.toLowerCase().includes(inputval.toLowerCase())
      );

      var sugg = favPlaces.suggested_places.filter((pl) =>
        pl.address_title.toLowerCase().includes(inputval.toLowerCase())
      );

      if (recentPlaces && recentPlaces.length > 0) {
        var recFil = recentPlaces.filter((pl) =>
          pl.address.toLowerCase().includes(inputval.toLowerCase())
        );
        setRecentPlaces(recFil);
      }

      setFavPlaces((prevFavPlaces) => ({
        ...prevFavPlaces,
        requests: filFav,
        suggested_places: sugg,
      }));

      autocompleteService.getPlacePredictions(
        options,
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const placesService = new google.maps.places.PlacesService(
              document.createElement("div")
            );

            // Get place details for each prediction to get the geometry (latitude and longitude)
            const detailedPredictions = predictions.map((prediction) => {
              return new Promise((resolve, reject) => {
                placesService.getDetails(
                  { placeId: prediction.place_id, fields: options1.fields },
                  (placeDetails, placeStatus) => {
                    if (
                      placeStatus === google.maps.places.PlacesServiceStatus.OK
                    ) {
                      const detailedPlace = {
                        ...placeDetails,
                        description: prediction.description,
                      };
                      resolve(detailedPlace);
                    } else {
                      reject(placeStatus);
                    }
                  }
                );
              });
            });
            Promise.all(detailedPredictions)
              .then((places) => {
                if (places) {
                  const detailedPredictions = places.map((place, index) => {
                    return {
                      ...place,
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                      address: place.description,
                      title: place.description,
                      name: "",
                      // favorite_id: index.toString()
                    };
                  });
                  setCombinePlaces(detailedPredictions);
                }
              })
              .catch((error) => {
                console.error("dest Error fetching place details:", error);
              });
          }
        }
      );
    }
  };

  const get_profile_data = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      setIsLoading(true);
      var res = await getProfileDataApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const get_request_driver = async () => {
    try {
      setIsLoading(true);
      let form = new FormData();
      form.append("request_id", requestId);
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);

      var res = await getRequestDriverApi(form);
      var data = res.data;

      if (data.status == "1") {
        var originalDate = data.driverdetail.ride_time;
        originalDate = TimeZone(originalDate);
        // console.log("test:",originalDate)
        // return false
        data.driverdetail.originalDate = originalDate;
        setRequestDetail(data);
        var price = data.userdetails.price;
        if (parseFloat(price) > 1) {
          var est_second = data.userdetails.estimatedprice_second;
          var est = price;
          if (
            parseFloat(est_second) > 0 &&
            parseFloat(price) != parseFloat(est_second)
          ) {
            est = price + " - " + est_second;
          }
          setEstimatedprice(est);
        }
        setDriverDetail(data.driverdetail);
        var pp = {
          lat: parseFloat(data.userdetails.pickup_latitude),
          lng: parseFloat(data.userdetails.pickup_longitude),
          text: data.driverdetail.estimated_arrival,
          add: data.userdetails.pickup_address,
        };
        setShowPickupMarker(true);
        setPosition(pp);
        setPickupPosition(pp);
        if (data.userdetails.destination_address != "") {
          var d_p = {
            lat: parseFloat(data.userdetails.destination_latitude),
            lng: parseFloat(data.userdetails.destination_longitude),
            text: "",
            add: data.userdetails.destination_address,
          };
          setDestinationPosition(d_p);
        }
        if (data.ride_preference != "") {
          var rp = data.ride_preference;
          setDoorAssistence(rp.door_assistance == "Yes" ? true : false);
          setTemperature(rp.temperature);
          setPrefNote(rp.customer_notes);
          setConversation(rp.talking);
        }

        // var r_s = parseInt(data.driverdetail.ride_status);
        // var arriveal_time = parseInt(data.driverdetail.driver_arrived_time);
        /*  if (r_s == 1) {
          setRideStatus("Accepted");
        } else if (arriveal_time != 0) {
          setRideStatus("Arrived");
        } else if (r_s == 2) {
          setRideStatus("Canceled");
          setShowCancel(false);
        } else if (r_s == 3) {
          setShowPickupMarker(false);
          setShowDestinationMarker(true);
          setRideStatus("Started");
          var d_p = {
            lat: parseFloat(data.userdetails.destination_latitude),
            lng: parseFloat(data.userdetails.destination_longitude),
            text: "",
          };
          setDestinationPosition(d_p);
        } else {
          setShowCar(false);
          setShowPickupMarker(false);
          setShowDestinationMarker(false);
          setRideStatus("Finished");
        } */
        return data;
      } else {
        console.log("get_request_driver", data);
      }
      console.log(data);
    } catch (error) {
      console.log("get_request_driver", error);
    } finally {
      setIsLoading(false);
    }
  };

  const aftersetDestinationPosition = (pickup, destination) => {
    if (pickup && destination) {
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(status);
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("ok", result);
            setRouteDirections(result);
            // setRequestRide(true);
          } else {
            console.log("err", result);
          }
          console.log(routeDirections);
        }
      );
    }
  };

  const cancelRideClick = () => {
    setCloseConfirmShow(true);
  };

  const user_location_request = async () => {
    try {
      var form = new FormData();
      form.append("request_id", requestId);
      form.append("user_type", 1);
      form.append("user_id", driverDetail.driver_id); // driver id
      form.append("current_user_id", userDetail.user_profile_id);
      form.append("current_user_type", userDetail.user_type);

      var res = await userLocationRequestApi(form);
      var data = res.data;
      setLocationDriver(data);
      if (data.status == "1") {
        var c_p = {
          lat: parseFloat(data.location.latitude),
          lng: parseFloat(data.location.longitude),
          text: "",
        };
        setCarPosition(c_p);
        if (data.is_finish_ride == 1) {
          setRideStatus("Finished");
          setShowCar(false);
          setShowPickupMarker(false);
          setShowDestinationMarker(false);
        } else if (data.is_cancel_ride == 1) {
          setRideStatus("Canceled");
          navigate("/dashboard");
          localStorage.removeItem("request_id");
        } else if (data.is_start_ride == 1) {
          setRideStatus("Started");
          setShowPickupMarker(false);
          if (driverDetail && driverDetail.destination_address != "") {
            if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
              var time_duration = await next_billion_time(
                enableFeaturesList.nextbillion_api_key,
                c_p,
                destinationPosition
              );
            } else {
              var time_duration = await get_estimated_time_google(
                c_p,
                destinationPosition
              );
            }
            if (time_duration != null) {
              const currentTime = new Date();
              const totalTime = new Date(
                currentTime.getTime() + time_duration * 1000
              );
              const options = {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              };
              setDestinationPosition({
                ...destinationPosition,
                text:
                  totalTime.toLocaleTimeString("en-US", options) + " Arrival",
              });
            }
            setPosition(destinationPosition);
            setShowDestinationMarker(true);
          }
        } else if (data.is_driver_arrived == 1) {
          setPickupPosition({
            ...pickupPosition,
            text: "Your ride has arrived!",
          });
          setRideStatus("Driver Arrived");
        }
      } else {
        if (data.message == "Invalid Auth token") {
          navigate("/login");
          localStorage.clear();
        }
      }
    } catch (error) {
      console.log("user_location_request", error);
    }
  };

  const removeDestinationClick = async () => {
    try {
      setDestinationPosition({});
      requestDetail.userdetails.destination_address = "";

      // var update_fare_res = await update_far_runningride(0,0,"");

      // if (update_fare_res.status == "1") {
      var change_ride_res = await change_riderequest_runningride(0, 0, "");
      if (change_ride_res.status == "1") {
        var estP =
          parseFloat(change_ride_res.estimatedprice) -
          parseFloat(change_ride_res.discount_price);
        var est2 =
          parseFloat(change_ride_res.estimatedprice_second) -
          parseFloat(change_ride_res.discount_price_second);
        var base =
          parseFloat(change_ride_res.base_price) -
          parseFloat(change_ride_res.base_fare_discount);
        setEstimatedprice(estP.toFixed(3));
        get_request_driver();
      } else {
        toast.error(change_ride_res.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
      // }
      handleDestinationClose();
    } catch (error) {
      console.error("removeDestinationClick", error);
    }
  };

  const cancle_riderequest = async (resone) => {
    try {
      var form = new FormData();
      //  is_update_app=1
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("cancelride_reason", resone ?? "I don’t need a ride anymore");
      form.append("request_id", requestId);
      form.append("is_update_app", "1");

      var res = await cancleRideRequestApi(form);
      console.log(res);
      var data = res.data;
      if (data.status == "1") {
        // navigate("/dashboard");
        toast("Your ride cancel successfully", {
          position: "top-center",
          autoClose: 2000,
        });
        // navigate("/ride-reciept/" + requestId);
        navigate("/dashboard");
        localStorage.removeItem("request_id");
      } else {
        toast(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const applyCouponRide = async () => {
    try {
      if (!couponCode.trim().length) {
        toast.error("Please enter coupon code", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }
      var timeZone = moment.tz.guess();
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", requestId);
      form.append("coupon_code", couponCode);
      form.append("timezone", timeZone);

      var res = await runningRideCoupon(form);
      var data = res.data;
      if (data.estimatedprice) {
        setEstimatedprice(data.estimatedprice);
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        var remove_coupon = document.querySelector(".bi.bi-x-square");
        remove_coupon.classList.remove("d-none");
        handleForMeClose();
        return false;
      }
      if (data.status == "0") {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log("applyCouponRide", error);
    }
  };

  const cancelCouponCodeHandle = async () => {
    try {
      setCouponCode("");
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", requestId);

      var res = await removeRideCoupon(form);
      var data = res.data;
      if (data.status == 1) {
        setEstimatedprice(data.estimatedprice);
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        var remove_coupon = document.querySelector(".bi.bi-x-square");
        remove_coupon.classList.add("d-none");
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log("cancelCouponCodeHandle", error);
    }
  };

  const fetchFavoriteLocations = async () => {
    try {
      let form = new FormData();
      form.append("customer_id", userDetail.user_profile_id);
      // form.append("user_type", userDetail.user_type);
      var res = await getFavPlaceApi(form);
      var data = res.data;
      if (data.status == "1") {
        setFavPlaces(data);
        setFavPlacesOriginal(data);
      } else {
        console.log("fetchFavoriteLocations ridedetail", data);
      }
    } catch (error) {
      console.log("fetchFavoriteLocations ridedetail", error);
    }
  };

  const checkFaveorNot = (add, val) => {
    if (favPlaces) {
      var fav = favPlaces.requests.find((pl) => pl.address == add);
      if (fav) {
        if (val == 0) {
          setTimeout(() => {
            if ("favorite_id" in fav) {
              document.getElementById("pick_up_save").src =
                "/assets/imgs/icons/Vector-fill.png";
              document
                .getElementById("pickup_save_icon")
                .classList.add("disable");
            } else {
              document.getElementById("pick_up_save").src =
                "/assets/imgs/icons/Vector.png";
              document
                .getElementById("pickup_save_icon")
                .classList.remove("disable");
            }
          }, 100);
        }
        return fav.name;
      } else {
        return add;
      }
    }
  };

  const rateHandler = () => {
    if (rate == "4" || rate == "5") {
      ratingHandlerApi();
    }
  };

  const ratingHandlerApi = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.user_id);
      form.append("driver_id", driverDetail.driver_id);
      form.append("request_id", requestId);
      form.append("ratting", rate);
      form.append("type", 1); // 1 for user
      form.append("reason", selectedReason ? selectedReason : "");
      var res = await ratingApi(form);
      var data = res.data;
      if (data.status == "1") {
        // setRateinf(data)
        navigate("/ride-reciept/" + requestId);
        localStorage.removeItem("request_id");
      } else {
        console.log("Error : while adding ratings", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPrefrences = async () => {
    try {
      const data1 = {
        request_id: requestId,
        temperature: temperature,
        customer_notes: prefNote,
        talking: conversation,
        user_id: userDetail.user_profile_id,
        user_type: userDetail.user_type,
        door_assistance: doorAssistence ? "Yes" : "No",
      };
      let form = new FormData();
      Object.entries(data1).forEach(([key, value]) => {
        form.append(key, value);
      });
      const res = await customerRidePreference(form);
      if (res.data.status == "1") {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 1500,
        });
        handlePrefrenceClose();
      } else {
        toast.error(res.data.message, {
          position: "top-center",
          autoClose: 1500,
        });
        handlePrefrenceClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <main>
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center vh-100 vw-100 z-1 position-absolute"
          style={{ backgroundColor: "rgba(255,255,255,0.6)" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div id="map">
        {pickupPosition && carPosition && (
          <MapWithAMarker
            containerElement={<div style={{ height: `90vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            destinationPosition={destinationPosition}
            position={position}
            pickupPosition={pickupPosition}
            zoom={zoom}
            showCar={showCar}
            showDestinationMarker={showDestinationMarker}
            showPickupMarker={showPickupMarker}
            carPosition={carPosition}
            routeDirections={routeDirections}
            showRoute={showRoute}
            driverArrive={driverArrive}
            rideFinished={rideFinished}
            dynamicCar={requestDetail.driverdetail.car_color}
          />
        )}
      </div>
      {!isLoading && pickupPosition && carPosition  && 
      <div className="bookingAndDriverDetailMainBox mt-20 p-0">
        {/* <div
          className="BookingActivity"
          style={{
            backgroundColor: "rgb(248 248 248)",
            backgroundPosition: "initial initial",
            backgroundRepeat: "initial initial",
          }}
        >
          <div className="row">
          
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p
                className="ec giveEllipsis fw-bold text-center mb-0 text-rove"
                style={{ color: "rgb(0, 0,0)", fontSize: 20 }}
              >
                Ride Details
              </p>
            </div>
          </div>
          <hr className="mb-0" />
        </div> */}
        <Accordion>
      <Accordion.Item eventKey="0" style={{backgroundColor:'transparent'}}>
            <Accordion.Header  className="BookingActivity"
          style={{
            backgroundColor: "rgb(248 248 248)",
            backgroundPosition: "initial initial",
            backgroundRepeat: "initial initial",
          }}>
              {/* <div> */}
          {/* <div className="row">
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p
                className="ec giveEllipsis fw-bold text-center mb-0 text-rove"
                style={{ color: "rgb(0, 0,0)", fontSize: 20 }}
              > */}
                Ride Details
              {/* </p>
            </div>
          </div> */}
          {/* <hr className="mb-0" />
              </div> */}
            </Accordion.Header>
        <Accordion.Body style={{padding:"0"}}>
       
        <div
          id="BookingDetailsMainDivHideShow"
          className="BookingDetailsMainDivShow pb-0"
        >
          <div className="my-2">
            <div className="d-flex justify-content-between">
              {estimatedprice ? (
                <>
                  <div className="w-50">
                    <p className="fw-bold mb-0">Estimated Fare </p>
                  </div>
                  <div className="w-50">
                    <p className="fw-bold mb-0 text-end">
                      {"KD " + estimatedprice}
                    </p>
                  </div>
                </>
              ) : (
                <p className="fw-bold mb-0">No estimated price</p>
              )}
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="col-md-6 d-flex align-items-center">
              <img
                src="/assets/imgs/icons/ic_mastercard-2.png"
                alt="ic_mastercard-2@2x"
                width={35}
              />
              <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                ####{" "}
                {userProfileData &&
                  userProfileData?.user_card_info?.card_number}
              </p>
            </div>
          </div>
          <hr />

          <div className="driverDetails">
            <div className="row my-2">
              <div className="col-md-6 col-6">
                <img
                  width={60}
                  height={60}
                  alt="https://www.riderove.com/dev_rove/uploads/carimage/1703152152_OIP_(1).jpeg"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  src={
                    driverDetail
                      ? "https://www.riderove.com/dev_rove/uploads/driver/" +
                        driverDetail.profileimage
                      : "/assets/imgs/ride_icons/Rider-image.jpg"
                  }
                  onError={(e) => {
                    e.target.src =
                      "https://www.riderove.com/dev_rove/uploads/carimage/1703152152_OIP_(1).jpeg";
                  }}
                />
              </div>
              <div className="col-md-6 col-6">
                <p className="fw-bold mb-1">
                  {driverDetail ? driverDetail.driver_name : "Loading..."}
                </p>
                <h6 className="fw-bold">
                  {driverDetail ? driverDetail.car_name_en : "Loading..."}
                </h6>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 col-6">
                <p className="fw-bold mb-1">Contact</p>
              </div>
              <div className="col-md-6 col-6">
                <h6 className="fw-bold">
                  {driverDetail ? driverDetail.mobile_number : "Loading..."}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-6">
                <p className="fw-bold mb-1">Ride Status</p>
              </div>
              <div className="col-md-6 col-6">
                <h6 className="fw-bold">
                  {rideStatus}
                  {/* {driverArrive
                    ? rideStart
                      ? rideFinished
                        ? "Ride finished"
                        : "Heading to destination"
                      : "Driver arrived"
                    : "Driver accept"} */}
                </h6>
              </div>
            </div>
            {showWaitingCharge && (
              <div className="row">
                <div className="col-md-6 col-6">
                  <p className="fw-bold mb-1">Waiting Charge</p>
                </div>
                <div className="col-md-6 col-6">
                  <h6 className="fw-bold">
                    {/* <MyComponent carTypes={ carTypes } driverDetail={driverDetail} /> */}
                    KD {parseFloat(waitingCharge).toFixed(3)}{" "}
                    {"00:00" != `0${minutes}:0${remainingSeconds}` ? (
                      reverseTime
                    ) : (
                      <span style={{ color: "red" }}>{timerCount}</span>
                    )}{" "}
                    Mins
                  </h6>
                </div>
              </div>
            )}
            {totalWaitingCharge && (
              <div className="row">
                <div className="col-md-6 col-6">
                  <p className="fw-bold mb-1">Waiting Charge</p>
                </div>
                <div className="col-md-6 col-6">
                  <h6 className="fw-bold">
                    {/* <MyComponent carTypes={ carTypes } driverDetail={driverDetail} /> */}
                    KD {parseFloat(totalWaitingCharge.charge).toFixed(3)}{" "}
                    {<span>{totalWaitingCharge.time}</span>} Mins
                  </h6>
                </div>
              </div>
            )}
            {showRideTiming && (
              <div className="row">
                <div className="col-md-6 col-6">
                  <p className="fw-bold mb-1">Duration of Ride</p>
                </div>
                <div className="col-md-6 col-6">
                  <h6 className="fw-bold"> {timerCount} Mins </h6>
                </div>
              </div>
            )}
            {showTotalTiming && (
              <div className="row">
                <div className="col-md-6 col-6">
                  <p className="fw-bold mb-1">Total Ride Time</p>
                </div>
                <div className="col-md-6 col-6">
                  <h6 className="fw-bold"> 15 Mins </h6>
                </div>
              </div>
            )}
            <div className="row my-3">
              <div className="col-12 d-flex mb-3 px-0">
                <div className="col-md-1 d-flex align-items-center">
                  <span>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      data-baseweb="icon"
                    >
                      <title>search</title>
                      <path
                        fillRule="evenodd"
                        clip-rule="evenodd"
                        d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm5-2a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="col-md-10 col-10 px-2">
                  <p
                    className="mb-0 text-secondary"
                    style={{ fontSize: "14px" }}
                  >
                    Pickup{" "}
                    {/* (
                    {requestDetail
                      ? requestDetail.userdetails.pickup_address.split(",")[0] +
                        ", " +
                        requestDetail.userdetails.pickup_address.split(",")[1]
                      : "Loading..."}
                    ) */}
                  </p>
                  <h6 className="fw-bold text-rove  mb-0">
                    {requestDetail
                      ? checkFaveorNot(
                          requestDetail.userdetails.pickup_address,
                          0
                        )
                      : "Loading..."}
                  </h6>
                </div>
                <div className="col-sm-1 col-md-1 d-flex align-items-center">
                  {requestDetail &&
                    requestDetail.userdetails.pickup_address != "" && (
                      <div
                        id="pickup_save_icon"
                        className=""
                        onClick={(e) => addToSavePlace(e, 0)}
                      >
                        <div className="">
                          <img
                            id="pick_up_save"
                            src={"/assets/imgs/icons/Vector.png"}
                            alt="ic_mastercard-2@2x"
                            width={30}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-12 d-flex mb-3 px-0">
                <div className="col-sm-1 col-md-1 d-flex align-items-center">
                  <span>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      data-baseweb="icon"
                    >
                      <title>search</title>
                      <path
                        fillRule="evenodd"
                        clip-rule="evenodd"
                        d="M14 10h-4v4h4v-4ZM7 7v10h10V7H7Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="col-md-10 col-10 px-2">
                  <p
                    className="mb-0 text-secondary"
                    style={{ fontSize: "14px" }}
                  >
                    Drop Off{" "}
                    {/* (
                    {requestDetail
                      ? requestDetail.userdetails.destination_address.split(
                          ","
                        )[0] +
                        ", " +
                        requestDetail.userdetails.destination_address.split(
                          ","
                        )[1]
                      : "Loading..."}
                    ) */}
                  </p>

                  {requestDetail &&
                  requestDetail.userdetails.destination_address != "" ? (
                    <h6
                      className="fw-bold text-rove  mb-0"
                      id="Location2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchDestination(true);
                        setShowAllSavedPlaces(false);
                      }}
                    >
                      {checkFaveorNot(
                        requestDetail.userdetails.destination_address,
                        1
                      )}
                    </h6>
                  ) : (
                    <h6
                      className="text-rove"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchDestination(true);
                        setShowAllSavedPlaces(false);
                      }}
                    >
                      Add Destination
                    </h6>
                  )}
                </div>
                <div className="col-sm-1 col-md-1 d-flex align-items-center">
                  {requestDetail &&
                    requestDetail.userdetails.destination_address != "" && (
                      <div
                        id="dest_save_icon"
                        className=""
                        onClick={(e) => addToSavePlace(e, 1)}
                      >
                        <div className="">
                          <img
                            id="dest_save"
                            src={"/assets/imgs/icons/Vector.png"}
                            alt="ic_mastercard-2@2x"
                            width={30}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-3 d-flex">
                <button className="btn btn-primary">
                  <i className="bi bi-telephone fs-5"></i>
                </button>
              </div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Send message..."
                />
              </div>
            </div>
          </div>
          <hr className="mb-0" />
          <div className="col-12 col-md-12 col-xl-12 col-lg-12 d-flex justify-content-evenly">
            <button
              type="button"
              className="btn btn-white preference w-45"
              style={{ background: "#fff", color: "#000", border: "none" }}
              onClick={() => setPrefrenceShow(true)}
            >
              Preference
            </button>
            <div className="vr"></div>
            <button
              type="button"
              className="btn btn-white w-45"
              style={{ background: "#fff", color: "#000", border: "none" }}
              onClick={() => setForMeShow(true)}
            >
              Coupon
            </button>
            <i
              className="bi bi-x-square fs-3 d-none"
              onClick={cancelCouponCodeHandle}
            ></i>
          </div>
          <hr className="my-0" id="lasthr" />
        </div>
        {showCancel && (
          <>
            {/* <hr className="mt-0"/> */}
            <div className="d-flex my-3">
              <button
                onClick={cancelRideClick}
                className="btn btn-cancle w-100"
                style={{ padding: "7px 57px" }}
              >
                Cancel Ride
              </button>
            </div>
          </>
        )}
        </Accordion.Body>
          </Accordion.Item>
          </Accordion>
      </div>
      }

      <Modal show={closeConfirmShow} onHide={handleCloseConfirm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ride</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-20">
            <h6 className="fw-bold">
              Are you sure you want to cancel your ride ?
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <Button
              className="btn btn-primary w-45"
              variant="primary"
              onClick={handleCloseConfirm}
            >
              No
            </Button>
            <button
              className="btn btn-primary w-45 mx-3"
              onClick={handleCancleRide}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={closeResoneShow} onHide={handleCloseResone} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ride</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            {cancel_reasons.map((item, inex) => (
              <div
                key={inex}
                style={{ cursor: "pointer" }}
                className={`col-12 col-md-12 d-flex align-items-center form-control ${
                  selectCancelReasone[item] ? "active" : ""
                } mb-2 pt-2 text-secondary`}
                onClick={() => {
                  handleCancelReasonChange(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
          {/* <div className="mx-20">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone1"
              />
              <label className="form-check-label" for="resone1">
                I don't need ride anymore
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone2"
              />
              <label className="form-check-label" for="resone2">
                I wan't to change my booking details
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone3"
              />
              <label className="form-check-label" for="resone3">
                The car is not suitable
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone4"
              />
              <label className="form-check-label" for="resone4">
                I couldn't contact the driver
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone5"
              />
              <label className="form-check-label" for="resone5">
                The driver isn't moving
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone6"
              />
              <label className="form-check-label" for="resone6">
                The driver couldn't find my location
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone7"
              />
              <label className="form-check-label" for="resone7">
                The driver is too far
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone8"
              />
              <label className="form-check-label" for="resone8">
                Other
              </label>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-danger "
              variant="primary"
              onClick={handleCloseResone}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary  mx-3"
              onClick={handleCancleRideReasone}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={forMeShow} onHide={handleForMeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Apply Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="mt-3">
            <div className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Enter coupon code"
                onChange={(e) => setCouponCode(e.target.value)}
                value={couponCode}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="justify-content-center"
          style={{ borderTop: "none" }}
        >
          {/* <div className="d-flex justify-content-between"> */}
          <button
            className="btn btn-outline-danger"
            onClick={handleForMeClose}
            type="button"
            style={{ padding: "8px 24px" }}
          >
            Cancel
          </button>
          <button className="btn btn-primary mx-3" onClick={applyCouponRide}>
            Apply
          </button>
          <i
            className="bi bi-x-square fs-1 d-none"
            onClick={cancelCouponCodeHandle}
          ></i>
          {/* </div> */}
        </Modal.Footer>
      </Modal>
      <Modal show={prefrenceShow} onHide={handlePrefrenceClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ride Preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-10">
            <h6 className="fw-bold">Door Assistance</h6>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={(e) => setDoorAssistence(e.target.checked)}
                checked={doorAssistence}
              />
            </div>
          </div>
          {/* <div className="mx-10">
            <h6 className="fw-bold my-3">Conversation</h6>

            <div className="row">
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation == "Don't Mind" ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation("Don't Mind")
                  }
                >
                  Don't Mind
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation == "No Thanks" ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation("No Thanks")
                  }
                >
                  No, Thanks
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation=="Yes Please" ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation("Yes Please")
                  }
                >
                  Yes, Please
                </div>
              </div>
            </div>
          </div> */}
          <div className="mx-10">
            <h6 className="fw-bold my-3">Temperature</h6>

            <div className="row">
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature == "Normal" ? "active" : ""
                  }`}
                  onClick={() => setTemperature("Normal")}
                >
                  Normal
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature == "Cold" ? "active" : ""
                  }`}
                  onClick={() => setTemperature("Cold")}
                >
                  Cold
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature == "Warm" ? "active" : ""
                  }`}
                  onClick={() => setTemperature("Warm")}
                >
                  Warm
                </div>
              </div>
            </div>
          </div>

          <div className="mx-10">
            <h6 className="fw-bold my-3">Additional Note</h6>
            <div className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Write somthing ..."
                style={{ background: "#ebebeb" }}
                value={prefNote}
                onChange={(e) => setPrefNote(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-danger"
              onClick={handlePrefrenceClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary  ms-3"
              type="button"
              onClick={setPrefrences}
            >
              Apply
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={rateing}
        onHide={() => {
          handleRatingClose();
          navigate("/dashboard");
          localStorage.removeItem("request_id");
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate Your Trip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="_css-imdnMt mb-5">
            {/* {console.log(driverDetail.map_image)} */}
            <img
              className="map_image"
              src={
                requestDetail
                  ? requestDetail.userdetails.map_image.replace(
                      "https://rovemain.rovesolution.com/uploads",
                      "https://www.riderove.com/dev_rove/uploads"
                    )
                  : ""
              }
            />
            <div className="_css-cAseJQ d-flex justify-content-between">
              <div className="d-flex w-50">
                <img
                  /*driverDetail
                   ? driverDetail.car_image.replace(
                      "https://roveimage.s3.me-south-1.amazonaws.com/images",
                      "https://www.riderove.com/dev_rove/uploads"
                    )
                  : "/assets/imgs/ride_icons/Rider-image.jpg" */

                  src={
                    driverDetail
                      ? "https://www.riderove.com/dev_rove/uploads/driver/" +
                        driverDetail.profileimage
                      : "/assets/imgs/ride_icons/Rider-image.jpg"
                  }
                  className="dropbtn"
                  style={{ height: "55px", width: "55px" }}
                  alt="person"
                />
                <div className="mx-3">
                  <h6 className="fw-bold text-secondary">
                    {driverDetail ? driverDetail.driver_name : ""}
                  </h6>
                  <p className="fw-bold text-rove">
                    {driverDetail && driverDetail
                      ? driverDetail.car_name_en
                      : ""}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-end w-50 mx-3">
                <div>
                  <h6 className="fw-bold text-secondary">
                    {driverDetail && driverDetail.originalDate}
                  </h6>
                  <p className="text-end fw-bold text-rove">
                    KD{" "}
                    <span className="fs-5">
                      {driverDetail && driverDetail.price}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="row">
                <div className="mx-3 mb-4 col-12 col-md-12 col-lg-12 col-sm-12 d-flex justify-content-center">
                  <div className="text-center">
                    {[...Array(5)].map((item, index) => {
                      const givenRating = index + 1;
                      return (
                        <img
                          key={index}
                          src={`/assets/imgs/icons/${
                            givenRating < rate || givenRating === rate
                              ? "star-fill"
                              : "star"
                          }.png`}
                          // onClick={() => { setRate(givenRating); }}
                          className="dropbtn rateing-img"
                          alt="start"
                          width={40}
                          height={20}
                          onClick={() => {
                            setRate(givenRating);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex align-items-center justify-content-center">
            <Rating width={30} height={30}></Rating>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button
            className="btn btn-light "
            onClick={() => {
              navigate("/ride-reciept/" + requestId);
              localStorage.removeItem("request_id");
              handleRatingClose();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary "
            onClick={() => {
              setRateinf(false);
              rateHandler();
              if (rate <= 3) {
                setRateReason(true);
              }
            }}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={rateReason}
        onHide={() => {
          handleRateReasonClose();
          navigate("/dashboard");
          localStorage.removeItem("request_id");
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            {/*  <div className="d-flex justify-content-center mb-4">
              <Rating width={30} height={30} initialState={3}></Rating>
            </div> */}

            <h6 className="fw-bold">Why did you rate {rate} stars ?</h6>

            <div className="row my-3 px-3">
              {Reasons.map((item, inex) => (
                <div
                  key={inex}
                  style={{ cursor: "pointer" }}
                  className={`col-12 col-md-12 form-control ${
                    selectRateReasone[item] ? "active" : ""
                  } my-2 pt-3 text-secondary`}
                  onClick={() => {
                    handleSelectReasone(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <button className="btn btn-light" onClick={handleRateReasonClose}>
            Cancel
          </button> */}
          <button
            className="btn btn-primary"
            onClick={() => {
              setRateReason(false);
              navigate("/ride-reciept/" + requestId);
              localStorage.removeItem("request_id");
              ratingHandlerApi();
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={searchDestination}
        onHide={handleDestinationClose}
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Switch rider</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container" style={{ minHeight: "600px" }}>
            <div className="row">
              <div className="col-md-12">
                <div
                  className={`search-item search-from`}
                  id="location-destination"
                >
                  <label>
                    <input
                      type="text"
                      className="form-control"
                      // id="Location2"
                      placeholder="Dropoff location"
                      ref={inputRef2}
                      name="destination"
                      autoComplete="off"
                      onChange={destinationAddressChange}
                    />
                  </label>
                </div>
                {Object.keys(destinationPosition).length > 0 && (
                  <div className=" d-flex justify-content-evenly mt-3">
                    <button
                      className="btn btn-locationmap w-45"
                      onClick={removeDestinationClick}
                    >
                      Remove destination
                    </button>
                  </div>
                )}
                <div className="my-3">
                  {combinePlaces && (
                    <>
                      <h6 className="fw-bold mb-4 mt-5">Search Places</h6>
                      {combinePlaces.length &&
                        combinePlaces.map((pl, index) => (
                          <div
                            className="my-3 d-flex align-items-center"
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => selectedDestination(pl)}
                          >
                            <img
                              src="/assets/imgs/icons/search.png"
                              alt="wallet@2x"
                              width={30}
                            />
                            <div className="mx-2">
                              <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                {pl.address.split(",")[0]}
                              </h6>
                              <p className="ms-2 mb-0">{pl.address}</p>
                            </div>
                          </div>
                        ))}
                    </>
                  )}

                  {favPlaces &&
                    favPlaces.suggested_places &&
                    favPlaces.suggested_places.length > 0 && (
                      <>
                        <h6 className="fw-bold mb-3 mt-5 text-secondary">
                          Suggested Places
                        </h6>
                        {favPlaces.suggested_places.map((sp, index) => {
                          if (sp.is_sublocation == "1") {
                            return (
                              <>
                                <div
                                  className="d-flex align-items-center"
                                  key={index}
                                >
                                  {/* <h6 className="fw-bold my-2 ">
                              {sp.address_title}
                            </h6> */}
                                  <img
                                    src="/assets/imgs/icons/Login_Logo.png"
                                    alt="wallet@2x"
                                    width={40}
                                  />
                                  <select
                                    name="suggested-place"
                                    id="suggested-place"
                                    className="form-select ms-3"
                                    onChange={handleSuggestedDestination}
                                  >
                                    <option value="0" defaultValue={true}>
                                      {sp.address_description}
                                    </option>
                                    {sp.sublocation.map((sl, index) => {
                                      sl.address = sl.name;
                                      return (
                                        <option
                                          value={JSON.stringify(sl)}
                                          key={index}
                                        >
                                          {sl.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  {favPlaces && favPlaces.requests.length > 0 && (
                    <>
                      <div className="d-flex justify-content-between">
                        <h6 className="fw-bold mb-4 mt-5 text-secondary">
                          Saved Places
                        </h6>

                        {favPlaces.requests.length > 5 &&
                          !showAllSavedPlaces && (
                            <h6
                              className="fw-bold mb-4 mt-5"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowAllSavedPlaces(true)}
                            >
                              Show All
                            </h6>
                          )}
                      </div>
                      {savedPlacesMap}

                      {/* {favPlaces.requests.map((sp, index) =>
                        <div className="my-3 d-flex align-items-center" key={index} style={{cursor:"pointer"}} onClick={()=>selectedDestination(sp)}>
                        <img
                          src={sp.address_type == "Home" ?  "/assets/imgs/icons/ic_home.png": (sp.address_type == "Work" ? "/assets/imgs/icons/ic_work.png" :"/assets/imgs/icons/star.png")}
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6> 
                          <p className="ms-2 mb-0">{sp.address}</p> 
                        </div>
                          
                      </div>
                        )} */}
                    </>
                  )}
                  {recentPlaces && recentPlaces.length > 0 && (
                    <>
                      <div className="d-flex justify-content-between">
                        <h6 className="fw-bold mb-4 mt-5 text-secondary">
                          Recent Places
                        </h6>
                        <h6
                          className="fw-bold mb-4 mt-5"
                          style={{ cursor: "pointer" }}
                          onClick={handleClearRecentPlaces}
                        >
                          Clear All
                        </h6>
                      </div>
                      <div>
                        {recentPlaces.length > 0 &&
                          recentPlaces.map((pl, index) => (
                            <div
                              className="my-3 d-flex align-items-center"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => selectedDestination(pl)}
                            >
                              <img
                                src="/assets/imgs/icons/icn_recent.png"
                                alt="search@2x"
                                width={30}
                              />
                              <div className="mx-2">
                                <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">
                                  {pl.address.split(",")[0]}
                                </h6>
                                <p className="ms-2 mb-0">{pl.address}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add To Saved Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add_place_form">
            <input type="hidden" name="lat" id="place_lat" />
            <input type="hidden" name="long" id="place_long" />
            <input type="hidden" name="address" id="place_address" />
            {/* <div className="row addfavplace d-flex justify-content-evenly">
              <div className="col-md-3 col-lg-3 h-25 w-25">
                {" "}
                <div
                  className={`d-flex  align-items-center justify-content-evenly ${
                    addressType[1] ? "select" : ""
                  }`}
                  onClick={() =>
                    setAddressType({
                      1: true,
                      2: false,
                      3: false,
                    })
                  }
                >
                  <i class="bi bi-house"></i>
                  Home
                </div>{" "}
              </div>
              <div className="col-md-3 col-lg-3  h-25 w-25">
                <div
                  className={`d-flex align-items-center justify-content-evenly ${
                    addressType[2] ? "select" : ""
                  }`}
                  onClick={() =>
                    setAddressType({
                      1: false,
                      2: true,
                      3: false,
                    })
                  }
                >
                  <i class="bi bi-briefcase"></i>
                  Work
                </div>
              </div>
              <div className="col-md-3 col-lg-3 w-25">
                <div
                  className={`d-flex align-items-center justify-content-evenly ${
                    addressType[3] ? "select" : ""
                  }`}
                  onClick={() =>
                    setAddressType({
                      1: false,
                      2: false,
                      3: true,
                    })
                  }
                >
                  <i class="bi bi-star"></i>
                  Other
                </div>
              </div>
            </div>
            {addressType[3] && (
              <div className="form-group mb-3 mt-3">
                <input
                  style={{ background: "#eee" }}
                  type="text"
                  className="form-control"
                  id="name1"
                  placeholder="Enter your title"
                />
              </div>
            )} */}
            <div className="form-group mb-3 mt-3">
              <input
                style={{ background: "#eee" }}
                type="text"
                className="form-control"
                id="name1"
                placeholder="Enter your title"
              />
            </div>
            <div className="my-3"></div>

            {/* <div className="mt-20">
                <MapWithAMarker
                  containerElement={<div style={{ height: `50vh` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  location={addPlace ? addPlace : Position}
                />
              </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            type="button"
            className="btn btn-primary"
            onClick={addPlaceHandler}
          >
            Add Place
          </button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default RideDetail;
